import React, { Component } from 'react';
import QueryString from 'query-string';

import Header from '../../../../components/Header/Header';
import './AuthRegister.scss';
import OtpModal from './OTPModal';
import ChooseOrganization from './ChooseOrganization';
import { RegisterCCOProps } from '../../container/CCO-Registration/AuthRegisterContainer';
import RegisterContainer from '../../container/CCO-Registration/AuthRegisterContainer';
import Loader from '../../../../components/Loader/Loader';
import ConfirmOrganizationAddress from './ConfirmAddress';
import ServicesQutionaries from './ServiceQuestionaries';
import CCORegister from './CCORegister';
import DoneConfiguring from './DoneConfiguration';
import ScrollAnimation from 'react-animate-on-scroll';
import ChooseUserType from './ChooseUserType';
import { history } from '../../../../routes';
import OrganisationProfile from '../../../CCOProfile/components/OrganisationProfile';

class AuthRegister extends Component<RegisterCCOProps> {
  state = {
    chooseUserType: true,
    openRegisterModal: false,
    openOtpModal: false,
    openOrganizationModal: false,
    openConfirmAddressModal: false,
    openServiceQuestionaries: false,
    openOrganistaionSetupModal: false,
    doneSettingUp: false,
    setUserInfo: {
      firstName: '',
      lastName: '',
      password: '',
      email: ''
    },
    organizationName: '',
    sessionNumber: '',
    charityNumber: '',
    usergroup: ''
  };

  componentDidMount() {
    const {
      location: { search }
    } = this.props;
    const queryParams = QueryString.parse(search);
    if (queryParams.resume_registration === 'true') {
      let sessionNumber: any = localStorage.getItem('session');
      if (
        sessionNumber !== null &&
        (sessionNumber === '0' || JSON.parse(sessionNumber).flag === '0')
      ) {
        this.setState({
          chooseUserType: false,
          openRegisterModal: false,
          openOrganizationModal: true,
          openOtpModal: false
        });
      }

      if (sessionNumber !== null && JSON.parse(sessionNumber).flag === '1') {
        this.setState({
          chooseUserType: false,
          openRegisterModal: false,
          openConfirmAddressModal: true,
          openOrganizationModal: false,
          openOtpModal: false
        });
      }

      if (sessionNumber !== null && JSON.parse(sessionNumber).flag === '2') {
        let address = {
          CharityName: JSON.parse(sessionNumber).CharityName,
          Line1: JSON.parse(sessionNumber).Line1,
          Line2: JSON.parse(sessionNumber).Line2,
          Line3: JSON.parse(sessionNumber).Line3,
          Postcode: JSON.parse(sessionNumber).Postcode,
          RegisteredCharityNumber: JSON.parse(sessionNumber)
            .RegisteredCharityNumber
        };
        this.confirmAddress(address);
      }

      if (sessionNumber !== null && JSON.parse(sessionNumber).flag === '3') {
        this.setState({
          chooseUserType: false,
          openRegisterModal: false,
          openConfirmAddressModal: false,
          openOrganizationModal: false,
          openOtpModal: false,
          openOrganistaionSetupModal: true,
        });
      }
    } 
  }

  setUserGroup = (user: any) => {
    this.setState(
      {
        usergroup: user
      },
      () => {
        setTimeout(() => {
          this.setState({
            chooseUserType: false,
            openRegisterModal: true
          });
        }, 500);
      }
    );
  };

  registerCCO = (firstName: any, lastName: any, email: any, password: any) => {
    const { usergroup } = this.state;
    this.setState(
      {
        setUserInfo: { firstName, lastName, email, password }
      },
      () => {
        this.props.registerCCO(firstName, lastName, email, password, usergroup);
      }
    );
  };

  resendOtp = () => {
    const { setUserInfo } = this.state;
    this.props.resendOtp(
      setUserInfo.firstName,
      setUserInfo.lastName,
      setUserInfo.email,
      setUserInfo.password
    );
  };

  setOtpMOdal = () => {
    this.setState({
      chooseUserType: false,
      openRegisterModal: false,
      openOtpModal: true
    });
  };

  verifyOtp = (ev: any, otp: string) => {
    ev.preventDefault();
    this.props.verifyOtp(
      this.state.setUserInfo.email,
      otp,
      this.state.usergroup
    );
  };

  verifyAndGetDetailsOfOrganization = (
    ev: any,
    chrityNumber: string,
    id: any
  ) => {
    ev.preventDefault();
    this.props.getCharityDetails(
      this.state.setUserInfo.email,
      chrityNumber,
      id
    );
  };

  confirmAddress = (address: any) => {
    this.setState(
      {
        organizationName: address.CharityName,
        charityNumber: address.RegisteredCharityNumber
      },
      () => {
        this.props.confirmAddress(this.state.setUserInfo.email, address);
      }
    );
  };

  confirmConfiguration = (services: any) => {
    let payload = {
      services,
      email: this.state.setUserInfo.email
    };
    this.props.saveServices(payload, this.state.charityNumber);
  };

  componentDidUpdate(prevProps: RegisterCCOProps, prevState: any) {
    if (
      this.props.fetch_register_cco_succeeded &&
      this.props.fetch_register_cco_succeeded !==
        prevProps.fetch_register_cco_succeeded
    ) {
      this.setOtpMOdal();
    }

    if (
      this.props.verify_otp_succeeded &&
      this.props.verify_otp_succeeded !== prevProps.verify_otp_succeeded
    ) {
      if (this.state.usergroup === 'cco_admin') {
        this.setState({
          openRegisterModal: false,
          openOrganizationModal: true,
          openOtpModal: false
        });
      } else {
        history.push('/auth/login');
      }
    }

    if (
      this.props.get_charity_details_succeeded &&
      this.props.get_charity_details_succeeded !==
        prevProps.get_charity_details_succeeded
    ) {
      localStorage.removeItem('session');
      this.setState({
        chooseUserType: false,
        openRegisterModal: false,
        openConfirmAddressModal: true,
        openOrganizationModal: false,
        openOtpModal: false
      });
    }

    if (
      this.props.confirm_charity_details_succeeded &&
      this.props.confirm_charity_details_succeeded !==
        prevProps.confirm_charity_details_succeeded
    ) {
      localStorage.removeItem('session');
      this.setState({
        chooseUserType: false,
        openRegisterModal: false,
        openConfirmAddressModal: false,
        openOrganizationModal: false,
        openOtpModal: false,
        openServiceQuestionaries: true,
        doneSettingUp: false
      });
    }

    if (
      this.props.save_services_succeeded &&
      this.props.save_services_succeeded !== prevProps.save_services_succeeded
    ) {
      localStorage.removeItem('session');
      localStorage.setItem("isRegisterFlow", "true")
      this.setState({
        chooseUserType: false,
        openRegisterModal: false,
        openConfirmAddressModal: false,
        openOrganizationModal: false,
        openOtpModal: false,
        openServiceQuestionaries: false,
        openOrganistaionSetupModal: true
      });
    }

    if (
      this.props.save_org_profile_succeeded &&
      this.props.save_org_profile_succeeded !== prevProps.save_org_profile_succeeded
    ) {
      localStorage.removeItem('session');
      this.setState({
        chooseUserType: false,
        openRegisterModal: false,
        openConfirmAddressModal: false,
        openOrganizationModal: false,
        openOtpModal: false,
        openServiceQuestionaries: false,
        openOrganistaionSetupModal: false,
        doneSettingUp: true
      });
    }
  }

  render() {
    const {
      openRegisterModal,
      openConfirmAddressModal,
      openOrganizationModal,
      openOtpModal,
      openServiceQuestionaries,
      doneSettingUp,
      chooseUserType,
      usergroup,
      openOrganistaionSetupModal
    } = this.state;

    const { loading } = this.props;

    return (
      <div>
        {loading && <Loader />}
        <Header activeTab="register" />
        <ScrollAnimation animateIn="fadeIn" delay={100}>
          <div className="register-cont">
            {chooseUserType && (
              <div className="register-cont-block">
                <div className="part-1 "></div>
                <ChooseUserType
                  setUserGroup={this.setUserGroup}
                  usergroup={usergroup}
                />
              </div>
            )}

            {openRegisterModal && (
              <div className="register-cont-block">
                <div className="part-1 "></div>
                <CCORegister registerCCO={this.registerCCO} />
              </div>
            )}

            {openOtpModal && (
              <div className="register-cont-block">
                <div className="part-1 "></div>
                <OtpModal
                  verifyOtp={this.verifyOtp}
                  resendOtp={() => this.resendOtp()}
                  fetch_register_cco_succeeded={
                    this.props.fetch_register_cco_succeeded
                  }
                  verify_otp_failed={this.props.verify_otp_failed}
                />
              </div>
            )}

            {openOrganizationModal && (
              <div className="register-cont-block">
                <div className="part-1 "></div>
                <div className="part-2">
                  <ChooseOrganization
                    verifyAndGetDetailsOfOrganization={
                      this.verifyAndGetDetailsOfOrganization
                    }
                  />
                </div>
              </div>
            )}

            {openConfirmAddressModal && (
              <ConfirmOrganizationAddress
                confirmAddress={this.confirmAddress}
                charity_details={this.props.charity_details}
              />
            )}

            {openServiceQuestionaries && (
              <ServicesQutionaries
                confirmConfiguration={this.confirmConfiguration}
                organizationName={this.state.organizationName}
                services={this.props.services}
              />
            )}

            {openOrganistaionSetupModal && (
              <div className="register-cont-block">
                <OrganisationProfile />
              </div>
            )}

            {doneSettingUp && <DoneConfiguring />}
          </div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default RegisterContainer(AuthRegister);
