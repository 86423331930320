import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class Privacy extends Component {
  render() {
    return (
      <div>
        <Header activeTab="about" />
        <div className="about-us-cont">
          <div className="about-us-header">
            <div className="title">Privacy Notice</div>
            <div className="about-header">Last updated September 20, 2020</div>
            <div className="block-cont">
              <div className="block" style={{ width: '100%' }}>
                <div className="content">
                  Thank you for choosing to be part of our community at
                  HelpBritain, operated by Ovyo Ltd ("HelpBritain", "we", "us",
                  "our"). We are committed to protecting your personal
                  information and your right to privacy. If you have any
                  questions or concerns about this privacy notice, or our
                  practices with regards to your personal information, please
                  contact us using the form.
                  <br />
                  <br />
                  When you visit our website http://www.HelpBritain.org.uk (the
                  "Website"), and more generally, use any of our services (the
                  "Services", which include the Website), we appreciate that you
                  are trusting us with your personal information. We take your
                  privacy very seriously. In this privacy notice, we seek to
                  explain to you in the clearest way possible what information
                  we collect, how we use it and what rights you have in relation
                  to it. We hope you take some time to read through it
                  carefully, as it is important. If there are any terms in this
                  privacy notice that you do not agree with, please discontinue
                  use of our Services immediately.
                  <br />
                  <br />
                  This privacy notice applies to all information collected
                  through our Services (which, as described above, includes our
                  Website), as well as, any related services, sales, marketing
                  or events.
                  <br />
                  <br />
                  Please read this privacy notice carefully as it will help you
                  understand what we do with the information that we collect.
                  <br />
                  <br />
                  <span>1. WHAT INFORMATION DO WE COLLECT?</span>
                  <br />
                  <br />
                  Personal information you disclose to us.
                  <br />
                  <br />
                  In Short: We collect personal information that you provide to
                  us.
                  <br />
                  <br />
                  We collect personal information that you voluntarily provide
                  to us when you register on the Website, express an interest in
                  obtaining information about us or our products and Services,
                  when you participate in activities on the Website (such as by
                  posting messages in our online forums or entering
                  competitions, contests or giveaways) or otherwise when you
                  contact us.
                  <br />
                  <br />
                  The personal information that we collect depends on the
                  context of your interactions with us and the Website, the
                  choices you make and the products and features you use. The
                  personal information we collect may include the following:
                  <br />
                  <br />
                  Personal Information Provided by You. We collect names; email
                  addresses; mailing addresses; usernames; passwords; contact or
                  authentication data; debit/credit card numbers; and other
                  similar information.
                  <br />
                  <br />
                  Payment Data. We may collect data necessary to process your
                  payment if you make purchases, such as your payment instrument
                  number (such as a credit card number), and the security code
                  associated with your payment instrument. All payment data is
                  stored by Stripe. You may find their privacy notice link(s)
                  here: https://stripe.com/gb/privacy.
                  <br />
                  <br />
                  All personal information that you provide to us must be true,
                  complete and accurate, and you must notify us of any changes
                  to such personal information.
                  <br />
                  <br />
                  Information automatically collected
                  <br />
                  <br />
                  In Short: Some information — such as your Internet Protocol
                  (IP) address and/or browser and device characteristics — is
                  collected automatically when you visit our Website.
                  <br />
                  <br />
                  We automatically collect certain information when you visit,
                  use or navigate the Website. This information does not reveal
                  your specific identity (like your name or contact information)
                  but may include device and usage information, such as your IP
                  address, browser and device characteristics, operating system,
                  language preferences, referring URLs, device name, country,
                  location, information about how and when you use our Website
                  and other technical information. This information is primarily
                  needed to maintain the security and operation of our Website,
                  and for our internal analytics and reporting purposes.
                  <br />
                  <br />
                  Like many businesses, we also collect information through
                  cookies and similar technologies.
                  <br />
                  <br />
                  The information we collect includes:Log and Usage Data. Log
                  and usage data is service-related, diagnostic, usage and
                  performance information our servers automatically collect when
                  you access or use our Website and which we record in log
                  files. Depending on how you interact with us, this log data
                  may include your IP address, device information, browser type
                  and settings and information about your activity in the
                  Website (such as the date/time stamps associated with your
                  usage, pages and files viewed, searches and other actions you
                  take such as which features you use), device event information
                  (such as system activity, error reports (sometimes called
                  'crash dumps') and hardware settings).Device Data. 
                  <br />
                  <br />
                  We collect
                  device data such as information about your computer, phone,
                  tablet or other device you use to access the Website.
                  Depending on the device used, this device data may include
                  information such as your IP address (or proxy server), device
                  and application identification numbers, location, browser
                  type, hardware model Internet service provider and/or mobile
                  carrier, operating system and system configuration
                  information.Location Data. We collect location data such as
                  information about your device's location, which can be either
                  precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access the
                  Website. For example, we may use GPS and other technologies to
                  collect geolocation data that tells us your current location
                  (based on your IP address). You can opt out of allowing us to
                  collect this information either by refusing access to the
                  information or by disabling your Location setting on your
                  device. Note however, if you choose to opt out, you may not be
                  able to use certain aspects of the Services.
                  <br />
                  <br />
                  <span>2. HOW DO WE USE YOUR INFORMATION?</span>
                  <br />
                  <br />
                  In Short: We process your information for purposes based on
                  legitimate business interests, the fulfilment of our contract
                  with you, compliance with our legal obligations, and/or your
                  consent.
                  <br />
                  <br />
                  We use personal information collected via our Website for a
                  variety of business purposes described below. We process your
                  personal information for these purposes in reliance on our
                  legitimate business interests, in order to enter into or
                  perform a contract with you, with your consent, and/or for
                  compliance with our legal obligations. We indicate the
                  specific processing grounds we rely on next to each purpose
                  listed below.
                  <br />
                  <br />
                  We use the information we collect or receive:To facilitate
                  account creation and logon process. If you choose to link your
                  account with us to a third-party account (such as your Google
                  or Facebook account), we use the information you allowed us to
                  collect from those third parties to facilitate account
                  creation and logon process for the performance of the
                  contract.Request feedback. We may use your information to
                  request feedback and to contact you about your use of our
                  Website.To enable user-to-user communications. We may use your
                  information in order to enable user-to-user communications
                  with each user's consent.To manage user accounts. We may use
                  your information for the purposes of managing our account and
                  keeping it in working order.To send administrative information
                  to you. We may use your personal information to send you
                  product, service and new feature information and/or
                  information about changes to our terms, conditions, and
                  policies.To protect our Services. We may use your information
                  as part of our efforts to keep our Website safe and secure
                  (for example, for fraud monitoring and prevention).To enforce
                  our terms, conditions and policies for business purposes, to
                  comply with legal and regulatory requirements or in connection
                  with our contract.To respond to legal requests and prevent
                  harm. If we receive a subpoena or other legal request, we may
                  need to inspect the data we hold to determine how to respond.
                  <br />
                  <br />
                  Fulfil and manage your orders. We may use your information to
                  fulfil and manage your orders, payments, returns, and
                  exchanges made through the Website
                  <br />
                  <br />
                  To deliver and facilitate delivery of services to the user. We
                  may use your information to provide you with the requested
                  service.
                  <br />
                  <br />
                  To respond to user inquiries/offer support to users. We may
                  use your information to respond to your inquiries and solve
                  any potential issues you might have with the use of our
                  Services.To send you marketing and promotional communications.
                  We and/or our charity partners may use the personal
                  information you send to us for our marketing purposes, if this
                  is in accordance with your marketing preferences. For example,
                  when expressing an interest in obtaining information about us
                  or our Website, subscribing to marketing or otherwise
                  contacting us, we will collect personal information from you.
                  You can opt-out of our marketing emails at any time (see the
                  "WHAT ARE YOUR PRIVACY RIGHTS" below).Deliver targeted
                  advertising to you. We may use your information to develop and
                  display personalized content and advertising (and work with
                  third parties who do so) tailored to your interests and/or
                  location and to measure its effectiveness.
                  <br />
                  <br />
                  <span>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span>
                  <br />
                  <br />
                  In Short: We only share information with your consent, to
                  comply with laws, to provide you with services, to protect
                  your rights, or to fulfill business obligations.
                  <br />
                  <br />
                  We may process or share your data that we hold based on the
                  following legal basis:Consent: We may process your data if you
                  have given us specific consent to use your personal
                  information for a specific purpose.Legitimate Interests: We
                  may process your data when it is reasonably necessary to
                  achieve our legitimate business interests.Performance of a
                  Contract: Where we have entered into a contract with you, we
                  may process your personal information to fulfill the terms of
                  our contract.Legal Obligations: We may disclose your
                  information where we are legally required to do so in order to
                  comply with applicable law, governmental requests, a judicial
                  proceeding, court order, or legal process, such as in response
                  to a court order or a subpoena (including in response to
                  public authorities to meet national security or law
                  enforcement requirements).Vital Interests: We may disclose
                  your information where we believe it is necessary to
                  investigate, prevent, or take action regarding potential
                  violations of our policies, suspected fraud, situations
                  involving potential threats to the safety of any person and
                  illegal activities, or as evidence in litigation in which we
                  are involved.More specifically, we may need to process your
                  data or share your personal information in the following
                  situations:Vendors, Consultants and Other Third-Party Service
                  Providers. We may share your data with third-party vendors,
                  service providers, contractors or agents who perform services
                  for us or on our behalf and require access to such information
                  to do that work. Examples include: payment processing, data
                  analysis, email delivery, hosting services, customer service
                  and marketing efforts. We may allow selected third parties to
                  use tracking technology on the Website, which will enable them
                  to collect data on our behalf about how you interact with our
                  Website over time. This information may be used to, among
                  other things, analyze and track data, determine the popularity
                  of certain content, pages or features, and better understand
                  online activity. Unless described in this notice, we do not
                  share, sell, rent or trade any of your information with third
                  parties for their promotional purposes. We have contracts in
                  place with our data processors, which are designed to help
                  safeguard your personal information. This means that they
                  cannot do anything with your personal information unless we
                  have instructed them to do it. They will also not share your
                  personal information with any organization apart from us. They
                  also commit to protect the data they hold on our behalf and to
                  retain it for the period we instruct.Third-Party Advertisers.
                  We may use third-party advertising companies to serve ads when
                  you visit or use the Website. These companies may use
                  information about your visits to our Website(s) and other
                  websites that are contained in web cookies and other tracking
                  technologies in order to provide advertisements about goods
                  and services of interest to you.Other Users. When you share
                  personal information (for example, by posting comments,
                  contributions or other content to the Website) or otherwise
                  interact with public areas of the Website, such personal
                  information may be viewed by all users and may be publicly
                  made available outside the Website in perpetuity. Similarly,
                  other users will be able to view descriptions of your
                  activity, communicate with you within our Website, and view
                  your profile.
                  <br />
                  <br />
                  <span>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</span>
                  <br />
                  <br />
                  In Short: We only share information with the following third
                  parties.
                  <br />
                  <br />
                  We only share and disclose your information with the following
                  third parties. We have categorized each party so that you may
                  be easily understand the purpose of our data collection and
                  processing practices. If we have processed your data based on
                  your consent and you wish to revoke your consent, please
                  contact us using the contact details provided in the section
                  below titled "HOW CAN YOU CONTACT US ABOUT THIS
                  NOTICE?".Advertising, Direct Marketing, and Lead
                  Generation:Google AdSenseAffiliate Marketing Programs:Amazon
                  AffiliationAllow Users to Connect to Their Third-Party
                  Accounts:Facebook account, Twitter account and Instagram
                  accountCloud Computing Services:Amazon Web Services
                  (AWS)Content Optimization:YouTube video embedFunctionality and
                  Infrastructure Optimization:Amazon Web ServicesInvoice and
                  Billing:Stripe and Blackhawk NetworksWebsite Performance
                  Monitoring:Amazon Web Services and Datadog
                  <br />
                  <br />
                  <span>
                    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </span>
                  <br />
                  <br />
                  In Short: We may use cookies and other tracking technologies
                  to collect and store your information.
                  <br />
                  <br />
                  We may use cookies and similar tracking technologies (like web
                  beacons and pixels) to access or store information. Specific
                  information about how we use such technologies and how you can
                  refuse certain cookies is set out in our Cookie Notice.
                  <br />
                  <br />
                  <span>6. DO WE USE GOOGLE MAPS?</span>
                  <br />
                  <br />
                  In Short: Yes, we use Google Maps for the purpose of providing
                  better service.
                  <br />
                  <br />
                  This Website uses Google Maps APIs which is subject to
                  Google’s Terms of Service. You may find the Google Maps APIs
                  Terms of Service here. To find out more about Google’s Privacy
                  Policy, please refer to this link. We obtain and store on your
                  device ('cache') your location. You may revoke your consent
                  anytime by contacting us at the contact details provided at
                  the end of this document.
                  <br />
                  <br />
                  <span>7. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                  <br />
                  <br />
                  In Short: We keep your information for as long as necessary to
                  fulfil the purposes outlined in this privacy notice unless
                  otherwise required by law.
                  <br />
                  <br />
                  We will only keep your personal information for as long as it
                  is necessary for the purposes set out in this privacy notice,
                  unless a longer retention period is required or permitted by
                  law (such as tax, accounting or other legal requirements).
                  Other than for UK tax and audit guidelines, we will only keep
                  your information for twelve (12) months past the termination
                  of the user's account.
                  <br />
                  <br />
                  When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize
                  such information, or, if this is not possible (for example,
                  because your personal information has been stored in backup
                  archives), then we will securely store your personal
                  information and isolate it from any further processing until
                  deletion is possible.
                  <br />
                  <br />
                  <span>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                  <br />
                  <br />
                  In Short: We aim to protect your personal information through
                  a system of organizational and technical security measures.
                  <br />
                  <br />
                  We have implemented appropriate technical and organizational
                  security measures designed to protect the security of any
                  personal information we process. However, despite our
                  safeguards and efforts to secure your information, no
                  electronic transmission over the Internet or information
                  storage technology can be guaranteed to be 100% secure, so we
                  cannot promise or guarantee that hackers, cybercriminals, or
                  other unauthorized third parties will not be able to defeat
                  our security, and improperly collect, access, steal, or modify
                  your information. Although we will do our best to protect your
                  personal information, transmission of personal information to
                  and from our Website is at your own risk. You should only
                  access the Website within a secure environment.
                  <br />
                  <br />
                  <span>9. WHAT ARE YOUR PRIVACY RIGHTS?</span>
                  <br />
                  <br />
                  In Short: In some regions, such as the European Economic Area,
                  you have rights that allow you greater access to and control
                  over your personal information. You may review, change, or
                  terminate your account at any time.
                  <br />
                  <br />
                  In some regions (like the European Economic Area), you have
                  certain rights under applicable data protection laws. These
                  may include the right to request access and obtain a copy of
                  your personal information, (ii) to request rectification or
                  erasure; (iii) to restrict the processing of your personal
                  information; and (iv) if applicable, to data portability. In
                  certain circumstances, you may also have the right to object
                  to the processing of your personal information. To make such a
                  request, please use the contact details provided below. We
                  will consider and act upon any request in accordance with
                  applicable data protection laws.
                  <br />
                  <br />
                  If we are relying on your consent to process your personal
                  information, you have the right to withdraw your consent at
                  any time. Please note however that this will not affect the
                  lawfulness of the processing before its withdrawal, nor will
                  it affect the processing of your personal information
                  conducted in reliance on lawful processing grounds other than
                  consent.
                  <br />
                  <br />
                  If you are a resident in the European Economic Area and you
                  believe we are unlawfully processing your personal
                  information, you also have the right to complain to your local
                  data protection supervisory authority. You can find their
                  contact details here:
                  http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                  <br />
                  <br />
                  If you are a resident in Switzerland, the contact details for
                  the data protection authorities are available here:
                  https://www.edoeb.admin.ch/edoeb/en/home.html.
                  <br />
                  <br />
                  Account Information
                  <br />
                  <br />
                  If you would at any time like to review or change the
                  information in your account or terminate your account, you can
                  log in to your account settings and update your user
                  account.Upon your request to terminate your account, we will
                  deactivate or delete your account and information from our
                  active databases. However, we may retain some information in
                  our files to prevent fraud, troubleshoot problems, assist with
                  any investigations, enforce our Terms of Use and/or comply
                  with applicable legal requirements.
                  <br />
                  <br />
                  Cookies and similar technologies: Most Web browsers are set to
                  accept cookies by default. If you prefer, you can usually
                  choose to set your browser to remove cookies and to reject
                  cookies. If you choose to remove cookies or reject cookies,
                  this could affect certain features or services of our Website.
                  <br />
                  <br />
                  Opting out of email marketing: You can unsubscribe from our
                  marketing email list at any time by clicking on the
                  unsubscribe link in the emails that we send or by contacting
                  us using the form. You will then be removed from the marketing
                  email list — however, we may still communicate with you, for
                  example to send you service-related emails that are necessary
                  for the administration and use of your account, to respond to
                  service requests, or for other non-marketing purposes.
                  <br />
                  <br />
                  <span>10. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                  <br />
                  <br />
                  Most web browsers and some mobile operating systems and mobile
                  applications include a Do-Not-Track ("DNT") feature or setting
                  you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and
                  collected. At this stage no uniform technology standard for
                  recognizing and implementing DNT signals has been finalized.
                  As such, we do not currently respond to DNT browser signals or
                  any other mechanism that automatically communicates your
                  choice not to be tracked online. If a standard for online
                  tracking is adopted that we must follow in the future, we will
                  inform you about that practice in a revised version of this
                  privacy notice.
                  <br />
                  <br />
                  <span>11. UPDATES TO THIS NOTICE</span>
                  <br />
                  <br />
                  We may update this privacy notice from time to time. The
                  updated version will be indicated by an updated "Revised" date
                  and the updated version will be effective as soon as it is
                  accessible.
                  <br />
                  <br />
                  <span>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </span>
                  <br />
                  <br />
                  If you have questions or comments about this notice, you can
                  use the contact form.
                  <br />
                  <br />
                  <span>
                    13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT FROM YOU?
                  </span>
                  <br />
                  <br />
                  Based on the applicable laws of your country, you may have the
                  right to request access to the personal information we collect
                  from you, change that information, or delete it in some
                  circumstances. To request to review, update, or delete your
                  personal information, please use the contact form. We will
                  endeavour to respond to your request within 30 days.This
                  privacy policy was created using Termly’s Privacy Policy
                  Generator.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Privacy;
