import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

interface DonationSuccessProps {
  show: boolean;
  closeModal: any;
  donatedAmount: any;
}
class DonationSuccess extends Component<DonationSuccessProps> {
  render() {
    const { show, closeModal, donatedAmount } = this.props;
    return (
      <>
        <Modal
          show={show}
          onHide={closeModal}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
          className="donation-success"
        >
          <Modal.Header>
            <Modal.Title></Modal.Title>
            <i
              className="fa fa-close donation-success-close"
              onClick={closeModal}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="donation-success-wrapper">
              <div className="donation-success__amount-wrapper">
                <div className="donation-success__amount">
                  £ {donatedAmount}
                </div>
              </div>
              <h4 className="donation-success__title">
                Thanks for your donation
              </h4>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default DonationSuccess;
