import React, { Component } from 'react';
import './Discovermore.scss';
import { LOCATION } from '../../config/constants';
import { calculate } from '../../views/Causes-Near-Me/components/Causes';
import noResultsFound from '../../images/no-resultfound.png';

interface DiscoverMoreProps {
  handleClose: () => void;
  filteredData: any;
  campaignInfo: any;
}

class DiscoverMore extends Component<DiscoverMoreProps> {
  render() {
    const location = localStorage.getItem(LOCATION);
    return (
      <div>
        <div className="discovermore-container">
          <div className="search-results-part">
            <div className="service-goals-part">
              <div className="heading-1">Charity services / Goals</div>
              <div className="service-goals-results">
                <div className="part-1">
                  {this.props.filteredData?.uniq?.map((d: any, i: number) => (
                    <div key={i}>
                      <a
                        href={`/all-causes?category=${d}&location=${location}&radius=5`}
                        className="item"
                        onClick={() =>
                          localStorage.setItem('filterSelected', d)
                        }
                      >
                        {d}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="campaigns-part">
              <div className="heading">Featured Campaigns</div>

              <div className="cards-camp">
                {this.props.filteredData?.featured
                  ?.filter((ds: any) => ds?.status?.toUpperCase() === 'ACTIVE')
                  .slice(0, 8)
                  .map((d: any, i: any) => (
                    <a
                      href={`/${d.charityNameUrl}/campaigns/${d.campaignNameUrl}`}
                      className="campaigns"
                      key={i}
                    >
                      <div className="image-part">
                        <img src={d.image} alt="" className="image" />
                      </div>
                      <div className="campaigns-details">
                        <div className="campaigns-name">{d.campaignName}</div>
                        <div className="content">{calculate(d.wishlist)}</div>
                      </div>
                    </a>
                  ))}
                  {this.props.filteredData?.featured
                  ?.filter((ds: any) => ds?.status?.toUpperCase() === 'ACTIVE').length === 0 &&
                    <div className="no-data-cont">
                      <img src={noResultsFound} alt="" className="img"/>
                    </div>
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DiscoverMore;
