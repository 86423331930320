import { connect } from 'react-redux';
import {
  getCampaign,
  getCharityWishlists,
  getWishlist
} from '../../../services/Campaigns/actions';
import { RouteComponentProps } from 'react-router-dom';

interface StateProps {
  loading: boolean;
  campaign_info: any;
  wishlist_info: any;
  campaign_succeeded: boolean;
  charity_info: any;
  charity_succeeded: boolean;
}

interface DispatchProps {
  getCampaign: (campaignName: string) => void;
  getCharity: (charityName: string) => void;
  getWishlist: (url: string) => void;
}

interface OwnProps extends RouteComponentProps {
  match: any;
}

export interface WishlistProps extends StateProps, DispatchProps, OwnProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    loading:
      state.campaign.fetch_campaign_loading ||
      state.campaign.fetch_charity_loading ||
      state.campaign.fetch_wishlist_loading,
    campaign_info: state.campaign.campaign_info,
    wishlist_info: state.campaign.wishlist_info,
    campaign_succeeded: state.campaign.fetch_campaign_succeeded,
    charity_info: state.campaign.charity_info,
    charity_succeeded: state.campaign.fetch_charity_succeeded
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    getCampaign: (campaignName: string) => dispatch(getCampaign(campaignName)),
    getCharity: (campaignName: string) =>
      dispatch(getCharityWishlists(campaignName)),
    getWishlist: (url: string) => dispatch(getWishlist(url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
