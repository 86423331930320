import React, { Component } from 'react';
import './Campaign.scss';
import { Button } from 'react-bootstrap';
import CampaignsList from './CampaignsList';
import CausesContainer, {
  CauseProps
} from '../../Causes-Near-Me/container/CausesContainer';
import Loader from '../../../components/Loader/Loader';
import { calculate } from '../../Causes-Near-Me/components/Causes';
import SweetAlert from 'react-bootstrap-sweetalert';
import { history } from '../../../routes';
import SocialShare from '../../../components/Share';

class Campaigns extends Component<CauseProps> {
  state = {
    selectedType: {
      value: 'ActiveCampaign',
      label: 'Active Campaigns'
    },
    openCreateCampaign: false,
    openCongratulationMessage: false,
    campaignName: '',
    publishInfo: {} as any,
    openFilterDropDown: false,
    openStatusDropDown: -1,
    selectedStatus: 'All'
  };

  componentDidMount() {
    if (!this.props.campaigns_succeeded) {
      this.props.getAdminCampaigns();
    }
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('click', this.handleClickOutsideDropdown);
  }

  componentDidUpdate(prevProps: CauseProps) {
    if (
      this.props.campaigns_succeeded &&
      this.props.campaigns_succeeded !== prevProps.campaigns_succeeded
    ) {
      this.forceUpdate();
    }
  }

  handleSelectType = (type: any) => {
    this.setState({
      selectedType: type
    });
  };

  handleOpenCreateCampaign = () => {
    this.setState({
      openCreateCampaign: !this.state.openCreateCampaign
    });
  };

  publishSucceeded = (campaignName: string, publish_info: any) => {
    this.setState({
      campaignName,
      openCreateCampaign: false,
      openCongratulationMessage: true,
      publishInfo: publish_info
    });
    this.props.getAdminCampaigns();
  };

  onConfirm = () => {};
  onCancel = () => {
    this.setState({
      openCongratulationMessage: false
    });
  };

  handleCancel = () => {
    this.setState({
      openCreateCampaign: false
    });
  };

  handleOpenFilterDrop = () => {
    this.setState({
      openFilterDropDown: !this.state.openFilterDropDown
    });
  };

  handleOpenSetStatus = (index: any) => {
    if (
      this.state.openStatusDropDown !== -1 &&
      index === this.state.openStatusDropDown
    ) {
      this.setState({
        openStatusDropDown: -1
      });
    } else {
      this.setState({
        openStatusDropDown: index
      });
    }
  };

  handleRedirect = (d: any, data: any) => {
    history.push(`/${d.charityNameUrl}/campaigns/${data.campaignNameUrl}`);
  };

  myRef: any = React.createRef();
  filterRef: any = React.createRef();

  handleClickOutside = (e: any) => {
    if (this.myRef.current !== null && !this.myRef.current.contains(e.target)) {
      this.setState({ openStatusDropDown: -1 });
    }
  };

  handleClickOutsideDropdown = (e: any) => {
    if (
      this.filterRef.current !== null &&
      !this.filterRef.current.contains(e.target)
    ) {
      this.setState({ openFilterDropDown: false });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('click', this.handleClickOutsideDropdown);
  }

  setSelectedStatus = (ev: any, status: any) => {
    ev.stopPropagation();
    this.setState({
      selectedStatus: status
    });
  };

  setCampaignStatus = (ev: any, status: any, campaignName: any) => {
    ev.stopPropagation();
    this.props.changeCampaignStatus(campaignName, status);
    setTimeout(() => {
      this.setState({
        openStatusDropDown: -1
      });
    }, 500);
  };

  render() {
    const { openCreateCampaign, openCongratulationMessage } = this.state;
    const { adminCampaignList } = this.props;
    return (
      <div>
        {this.props.loading && <Loader />}
        {/* <Header activeTab="campaigns" /> */}
        <div className="ad-banner ad-banner-campaign">
          Looking for ideas on how to maximise the donations your charity can
          receive through HelpBritain?{' '}
          <a href="/ideas" className="how-links">
            Click here for some ideas we've put together for you.
          </a>
        </div>

        {adminCampaignList.Item !== undefined &&
          adminCampaignList.Item.campaigns === undefined &&
          !this.props.loading &&
          !openCreateCampaign && (
            <div className="campaign-cont-first">
              <div className="first-time">
                <div className="campaign-plus-btn">
                  <div className="image">+</div>
                </div>
                Hey, looks like this is your first time here. <br />
                Let’s start from here
                <Button
                  className="btn-default create-campaign-btn"
                  onClick={this.handleOpenCreateCampaign}
                >
                  Create new wishlist
                </Button>
              </div>
            </div>
          )}

        {adminCampaignList.Item !== undefined &&
          adminCampaignList.Item.campaigns !== undefined &&
          !openCreateCampaign && (
            <div className="campaign-cont">
              <div className="campaign-header">
                <div className="contents">
                  <div className="headind-text">My Wishlists</div>
                  <div className="link-text"></div>
                  <div className="drop-menu">
                    <div
                      className="drop-name"
                      ref={this.filterRef}
                      onClick={this.handleOpenFilterDrop}
                    >
                      <span>{this.state.selectedStatus}</span>
                      <span className="fa fa-angle-down" />
                    </div>
                    {this.state.openFilterDropDown && (
                      <div className="drop">
                        <span
                          className="items-drop"
                          onClick={(ev) => this.setSelectedStatus(ev, 'All')}
                        >
                          All
                        </span>

                        <span
                          className="items-drop"
                          onClick={(ev) => this.setSelectedStatus(ev, 'Active')}
                        >
                          Active
                        </span>

                        <span
                          className="items-drop"
                          onClick={(ev) =>
                            this.setSelectedStatus(ev, 'Inactive')
                          }
                        >
                          Inactive
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="causes">
                <div className="campaign-cause-cont" style={{ width: '100%' }}>
                  <div className="cause-container">
                    <div className="cards-container">
                      <div
                        className="cards create-card"
                        onClick={this.handleOpenCreateCampaign}
                      >
                        <div className="create">
                          <div className="plus-icon-cont">
                            <div className="image">+</div>
                          </div>
                          <div className="create-text">Create new wishlist</div>
                        </div>
                      </div>

                      {adminCampaignList?.Item.campaigns
                        .filter((ds: any) =>
                          this.state.selectedStatus === 'All'
                            ? ds
                            : ds.status === this.state.selectedStatus
                        )
                        .map((data: any, index: number) => (
                          <div className="cards" key={index}>
                            <div
                              className="drop-menu"
                              onClick={() => this.handleOpenSetStatus(index)}
                              ref={this.myRef}
                            >
                              <div className="drop-name">
                                <span>{data.status}</span>
                                <span className="fa fa-angle-down" />
                              </div>
                              {this.state.openStatusDropDown === index && (
                                <div className="drop" ref={this.myRef}>
                                  {this.state.selectedStatus !== 'Active' && (
                                    <span
                                      className="items-drop"
                                      onClick={(e) =>
                                        this.setCampaignStatus(
                                          e,
                                          'Active',
                                          data.campaignName
                                        )
                                      }
                                    >
                                      Active
                                    </span>
                                  )}
                                  {this.state.selectedStatus !== 'Inactive' && (
                                    <span
                                      className="items-drop"
                                      onClick={(e) =>
                                        this.setCampaignStatus(
                                          e,
                                          'Inactive',
                                          data.campaignName
                                        )
                                      }
                                    >
                                      Inactive
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                            <div
                              className="img-container"
                              style={{
                                backgroundImage: `url(${data.image})`
                              }}
                              onClick={() =>
                                this.handleRedirect(
                                  adminCampaignList.Item,
                                  data
                                )
                              }
                            />
                            <a
                              className="cause-info"
                              href={`/${adminCampaignList.Item.charityNameUrl}/campaigns/${data.campaignNameUrl}`}
                            >
                              <div className="cause-title">
                                {data.campaignName}
                              </div>
                              <div className="organization">
                                {adminCampaignList.Item.line3}
                              </div>
                              <div className="description">
                                {adminCampaignList.Item.charityName}
                                {' - '}
                                {data.description}
                              </div>

                              <div className="funds-progress">
                                {calculate(data.wishlist)}
                              </div>
                            </a>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {openCreateCampaign && (
          <CampaignsList
            handlePublish={this.publishSucceeded}
            goBack={this.handleCancel}
          />
        )}
        {openCongratulationMessage && (
          <SweetAlert
            custom
            showCancel={false}
            showCloseButton
            showConfirm={false}
            confirmBtnText="Yes"
            cancelBtnText="No"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="light"
            customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
            title="Congratulations.!"
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          >
            <div className="congrats-container">
              <div className="cong-text-1">
                The campaign {this.state.publishInfo.campaignName}
                <br />
                is successfully published. You can check it here:
              </div>
              <a href={this.state.publishInfo.url} className="link">
                {this.state.publishInfo.url}
              </a>

              <SocialShare
                ShareUrl={`${this.state.publishInfo.url}`}
                linkUrl={`${this.state.publishInfo.url}`}
                quote={`I just found this wishlist for ${this.state.publishInfo.campaignName} on HelpBritain - help me support them! \n`}
              />
            </div>
          </SweetAlert>
        )}
      </div>
    );
  }
}

export default CausesContainer(Campaigns);
