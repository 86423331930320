import * as types from './types';
import { notify } from '../../../components/Notifier/Notifier';
import {
  authorizer,
  user_authorizer,
  adminAuthorizer
} from '../../../config/constants';
import { history } from '../../../routes';
import axios from 'axios';
import { baseUrl } from '../../../config/api-config';

export const loginCCO = (email: string, password: string, pushTo?: any) => (
  dispatch: any
) => {
  let payload = { username: email, password };
  dispatch({ type: types.LOGIN_CCO_REQUESTED });
  axios
    .post(baseUrl + '/hb/signIn', payload)
    .then((data: any) => {
      if (data.data.usergroup === 'hb_user') {
        user_authorizer.setHeader(data.data.token);
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${data.data.token}`;
        localStorage.setItem('email', email);
        localStorage.removeItem('__is_loggedIn');
        if (pushTo) {
          history.push(pushTo);
        } else {
          history.push(`/`);
        }

        dispatch({ type: types.LOGIN_CCO_SUCCEEDED });
        notify.success('Success', 'Successfully logged in !');
      }

      if (data.data.usergroup === 'cco_admin') {
        localStorage.removeItem('__is_user_loggedIn');
        if (data.data.session === undefined) {
          localStorage.setItem('__auth', data.data.token);
          axios.defaults.headers.common = {
            Authorization: `Bearer ${data.data.token}`
          };
          localStorage.setItem('session', '0');
          localStorage.setItem('email', email);
          history.push('/auth/register?resume_registration=true');
          notify.success('Verified', 'Please complete your registrtion !');
        }

        if (data.data.session !== undefined) {
          if (data.data.session?.flag?.toString() === '4') {
            authorizer.setHeader(data.data.token);
            localStorage.setItem('email', email);
            axios.defaults.headers.common = {
              Authorization: `Bearer ${data.data.token}`
            };
            if (pushTo) {
              history.push(pushTo);
            } else {
              history.push(`/profile`);
            }

            dispatch({ type: types.LOGIN_CCO_SUCCEEDED });
            notify.success('Success', 'Successfully logged in !');
          } else {
            localStorage.setItem('session', JSON.stringify(data.data.session));
            localStorage.setItem('__auth', data.data.token);
            localStorage.setItem('email', email);
            history.push('/auth/register?resume_registration=true');
            dispatch({ type: types.LOGIN_CCO_SUCCEEDED });
            notify.success('Verified', 'Please complete your registrtion !');
          }
        }
      }

      if (data.data.usergroup === 'hb_admin') {
        adminAuthorizer.setHeader(data.data.token);
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${data.data.token}`;
        localStorage.setItem('email', email);
        if (pushTo) {
          history.push(pushTo);
        } else {
          history.push(`/admin`);
        }

        dispatch({ type: types.LOGIN_CCO_SUCCEEDED });
        notify.success('Success', 'Successfully logged in !');
      }
    })
    .catch((error: any) => {
      dispatch({ type: types.LOGIN_CCO_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const sendMyPassword = (email: string) => (dispatch: any) => {
  dispatch({ type: types.SEND_PASSWORD_REQUESTED });
  axios
    .post(baseUrl + '/hb/forgotPasswordOTP', { email })
    .then(() => {
      dispatch({ type: types.SEND_PASSWORD_SUCCEEDED });
      notify.success('Success', 'Mail sent successfully!');
    })
    .catch((error: any) => {
      dispatch({ type: types.SEND_PASSWORD_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const setMyPassword = (
  email: string,
  otp: string,
  newPassword: string
) => (dispatch: any) => {
  dispatch({ type: types.SET_PASSWORD_REQUESTED });
  axios
    .post(baseUrl + '/hb/forgotPassword', { email, otp, password: newPassword })
    .then(() => {
      dispatch({ type: types.SET_PASSWORD_SUCCEEDED });
      notify.success('Success', 'Password reset successfully!');
    })
    .catch((error: any) => {
      dispatch({ type: types.SET_PASSWORD_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};
