import React, { Component } from 'react';
import './OrganisationDetails.scss';
import Header from '../../../components/Header/Header';
import { calculate } from '../../Causes-Near-Me/components/Causes';
import Loader from '../../../components/Loader/Loader';
import WishlistContainer, {
  WishlistProps
} from '../../CampaignDetails/container/WishlistContainer';
import GoogleMaps from '../../../components/GoogleMap/GoogleMap';
import Footer from '../../../components/Footer/Footer';
import SocialShare from '../../../components/Share';
import ReadMoreReact from '../../../components/ReadMore';

class Wishlist extends Component<WishlistProps> {
  state = {
    content: {} as any,
    isLoading: false
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.props.getCharity(params.charity_url);
  }

  componentDidUpdate(prevProps: WishlistProps) {
    if (
      this.props.charity_succeeded &&
      this.props.charity_succeeded !== prevProps.charity_succeeded
    ) {
      this.setState({
        content: this.props.charity_info
      });
    }
  }

  getSumOfNeeded = (data: any) => {
    let sumOfRequestedAmount = 0;

    for (let i = 0; i < data.length; i++) {
      sumOfRequestedAmount += parseInt(data[i].RequestedAmount);
    }
    return sumOfRequestedAmount;
  };

  getSumOfReceived = (data: any) => {
    let sumOfPurchasedAmount = 0;

    for (let i = 0; i < data.length; i++) {
      sumOfPurchasedAmount += parseInt(data[i].PurchasedAmount);
    }
    return sumOfPurchasedAmount;
  };

  render() {
    const { content } = this.state;
    const { match } = this.props;
    const is_admin_loggedin = localStorage.getItem('__is_admin_loggedIn');
    return (
      <div>
        <Header />
        {this.props.loading && <Loader />}
        {!this.props.loading && (
          <div className="wishlist-main-cont">
            {is_admin_loggedin && (
              <div
                className="back-cont"
                style={{ width: '90%', padding: '0', marginTop: '2rem' }}
              >
                <a
                  className="back"
                  href={`/admin/manage_organisations`}
                  style={{ fontSize: '1.2rem' }}
                >
                  <i
                    className="fa fa-arrow-circle-o-left"
                    style={{ fontSize: '1.6rem' }}
                  ></i>
                  Back
                </a>
              </div>
            )}
            <div className="wishlist-cont">
              {(content.logo !== undefined ||
                content.oneLiner !== undefined ||
                content.description !== undefined) && (
                <div className="wishlist-part-1">
                  <div className="about-organisation-cont">
                    {content.logo !== undefined && (
                      <div
                        className="banner-cont"
                        style={{ backgroundImage: `url(${content.banner})` }}
                      >
                        <img src={content.logo} className="logo-cont" alt="" />
                      </div>
                    )}

                    <div className="description-cont">
                      <div className="organisation-name">
                        {content.CharityName}
                      </div>

                      <div
                        className="description"
                        style={{ fontSize: '1.5rem', fontWeight: 600 }}
                      >
                        {content.oneLiner}
                      </div>

                      {content.description !== undefined && (
                        <div className="description">
                          <ReadMoreReact
                            min={200}
                            ideal={300}
                            max={1000}
                            text={content.description}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="wishlist-part-2">
                {content.services !== undefined && content.services.length > 0 && (
                  <div className="service-container">
                    <div className="sub-heading">Service Areas / Goals</div>
                    <div className="services">
                      {content.services.map((d: any, index: number) => (
                        <div className="service-badge" key={index}>
                          {d}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {content.charityNameUrl !== undefined && (
                  <div className="address-container">
                    <div className="sub-heading">Charity Url</div>
                    <div className="address">
                      https://www.helpbritain.org.uk/charity/
                      {content.charityNameUrl}
                    </div>
                  </div>
                )}

                <div className="location-container">
                  <div className="sub-heading">
                    {content.deliveryAdress !== undefined
                      ? 'Delivery Address'
                      : 'Registered Address'}
                  </div>
                  {content.latitude !== undefined &&
                    content.longitude !== undefined && (
                      <div className="google-map-cont">
                        <GoogleMaps
                          lat={content.latitude}
                          lon={content.longitude}
                        />
                      </div>
                    )}
                  {content.deliveryAdress !== undefined ? (
                    <div className="address-container">
                      <div className="address">
                        {content.deliveryAdress.Line1 +
                          ', ' +
                          content.deliveryAdress.Line2 +
                          ', ' +
                          content.deliveryAdress.Line3 +
                          ', ' +
                          content.deliveryAdress.Postcode}
                      </div>
                    </div>
                  ) : (
                    <div className="address-container">
                      <div className="address">
                        {content.Line1 +
                          ', ' +
                          content.Line2 +
                          ', ' +
                          content.Line3 +
                          ', ' +
                          content.Postcode}
                      </div>
                    </div>
                  )}
                </div>

                {content.RegisteredCharityNumber !== undefined && (
                  <div className="address-container">
                    <div className="sub-heading">Registered Charity Number</div>
                    <div className="address">
                      {content.RegisteredCharityNumber}
                    </div>
                  </div>
                )}

                <div className="social-media-container">
                  <div className="sub-heading">
                    Share organisation on social media
                  </div>
                  <div className="icon-main-container">
                    <SocialShare
                      ShareUrl={`https://www.helpbritain.org.uk${match.url}`}
                      linkUrl={`https://www.helpbritain.org.uk${match.url}`}
                      quote={`I just found this wishlist for ${content.CharityName} on HelpBritain - help me support them! \n`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="causes">
              {content.campaigns !== undefined && content.campaigns.length > 0 && (
                <div className="cause-container">
                  {content.campaigns.filter(
                    (d: any) => d.type === 'Amazon' && d.status === 'Active'
                  ).length > 0 && (
                    <div className="heading-cause">
                      <div className="title">
                        <img
                          src={require('../../../images/Dashboard/amazon.jpg')}
                          alt=""
                          className="amazon"
                        />
                      </div>
                    </div>
                  )}

                  <div className="cards-container">
                    {content.campaigns
                      .filter(
                        (d: any) => d.type === 'Amazon' && d.status === 'Active'
                      )
                      .map((data: any, index: number) => (
                        <a
                          href={`/${content.charityNameUrl}/campaigns/${data.campaignNameUrl}`}
                          className="cards"
                          key={index}
                        >
                          <div
                            className="img-container"
                            style={{
                              backgroundImage: `url(${data.image})`
                            }}
                          />
                          <div className="cause-info">
                            <div className="cause-title">
                              {data.campaignName}
                            </div>
                            <div className="organization">{content.line3}</div>
                            <div className="description">
                              {content.charityName}
                              {' - '}
                              {data.description}
                            </div>

                            <div className="funds-progress">
                              {calculate(data.wishlist)}
                            </div>
                          </div>
                        </a>
                      ))}
                  </div>

                  {content.campaigns.filter(
                    (d: any) => d.type === 'Love2Shop' && d.status === 'Active'
                  ).length > 0 && (
                    <div className="heading-cause">
                      <div className="title">
                        <img
                          src={require('../../../images/select.png')}
                          alt=""
                          className="l2s"
                        />
                      </div>
                    </div>
                  )}

                  <div className="cards-container">
                    {content.campaigns
                      .filter(
                        (d: any) =>
                          d.type === 'Love2Shop' && d.status === 'Active'
                      )
                      .map((data: any, index: number) => (
                        <a
                          href={`/${content.charityNameUrl}/campaigns/${data.campaignNameUrl}`}
                          className="cards"
                          key={index}
                        >
                          <div
                            className="img-container"
                            style={{
                              backgroundImage: `url(${data.image})`
                            }}
                          />
                          <div className="cause-info">
                            <div className="cause-title">
                              {data.campaignName}
                            </div>
                            <div className="organization">{content.line3}</div>
                            <div className="description">
                              {content.charityName}
                              {' - '}
                              {data.description}
                            </div>

                            <div className="funds-progress">
                              {calculate(data.wishlist)}
                            </div>
                          </div>
                        </a>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {!this.props.loading && <Footer />}
      </div>
    );
  }
}

export default WishlistContainer(Wishlist);
