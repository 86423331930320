import * as types from './types';
import axios from 'axios';
import { baseUrl } from '../../config/api-config';
import { notify } from '../../components/Notifier/Notifier';

export const toggleSearch = () => (dispatch: any) => {
  dispatch({ type: types.TOGGLE_SEARCH });
};
export const setLatLong = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.SET_LAT_LONG, payload });
};
export const setLocation = (location: string) => (dispatch: any) => {
  localStorage.setItem('__location', location);
  dispatch({ type: types.SET_LOCATION, payload: location });
};

export const searchCampaign = (search: string, city: string, type: string) => (
  dispatch: any,
  getState: any
) => {
  const {
    search: { latitude, longitude }
  } = getState();
  dispatch({ type: types.SEARCH_ORG_REQUESTED });
  axios
    .get(
      baseUrl +
        `/hb/searchCampaign?search=${search}&city=${city}&type=${type}&lat=${latitude}&long=${longitude}`
    )
    .then((payload: any) =>
      dispatch({ type: types.SEARCH_ORG_SUCCEEDED, payload })
    )
    .catch((error: any) => {
      dispatch({ type: types.SEARCH_ORG_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};
