import React, { Component } from 'react';
import ProfileContainer, {
  CCOProfileProps
} from '../../views/CCOProfile/container/ProfileContainer';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { doLogout } from '../../config/constants';

class AdminNavbar extends Component<CCOProfileProps> {
  componentDidMount() {
    if (localStorage.getItem('__is_admin_loggedIn') === 'true') {
      this.props.getProfile();
    }
  }

  render() {
    return (
      <Navbar.Collapse id="basic-navbar-nav" className="show-mine">
        <Nav className="my-dropdown ml-auto">
          {this.props.profile_info !== '' && (
            <NavDropdown
              title={
                this.props.profile_info.firstName[0] +
                this.props.profile_info.lastName[0]
              }
              id="basic-nav-dropdown own-dropdown"
            >
              <NavDropdown.Item className="profile-name-cont">
                <div className="profile-name">
                  {this.props.profile_info.firstName +
                    ' ' +
                    this.props.profile_info.lastName}
                </div>
                <div className="email">{this.props.profile_info.email}</div>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className="logout-cont" onClick={doLogout}>
                <div className="name">Logout</div>
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    );
  }
}

export default ProfileContainer(AdminNavbar);
