import React, { Component } from 'react';
import { Button, Table } from 'react-bootstrap';
import Footer from '../../../components/Footer/Footer';
import Loader from '../../../components/Loader/Loader';
import Header from '../../../components/Header/Header';
import GoogleMaps from '../../../components/GoogleMap/GoogleMap';
import CircularProgress from '../../../components/CircularProgress';
import VolunteeringDetailsContainer, {
  VolunteeringDetailsProps
} from '../containers/VolunteeringDetails';
import { withRouter } from 'react-router';
import AddMeAsVolunteer from './AddMeAsVolunteer';
import SocialShare from '../../../components/Share';

class VolunteeringDetails extends Component<VolunteeringDetailsProps> {
  componentDidMount(): void {
    const {
      getSingleVolunteering,
      match: {
        params: { id }
      }
    } = this.props;
    getSingleVolunteering(id);
  }

  render() {
    const { loading, current_volunteering, charityInfo } = this.props;
    let is_cco_loggedin: any = localStorage.getItem('__is_loggedIn') || false;
    if (!current_volunteering) return null;
    return (
      <div>
        {current_volunteering === undefined && <Loader />}
        <Header activeTab="volunteering" />
        {current_volunteering !== undefined && (
          <div className="v-details-wrapper">
            <div className="expands-part-2">
              <div className="right-logo-part">
                <div
                  className="image-part"
                  style={{
                    backgroundImage: `url(${current_volunteering.bannerImage})`
                  }}
                ></div>
                <div className="org-wrapper">
                  <img src={charityInfo.logo} alt="" />
                  <div style={{ marginLeft: '1.2rem' }}>
                    <a
                      href={`/charity/${charityInfo.charityNameUrl}`}
                      className="name"
                    >
                      {charityInfo.CharityName}
                    </a>
                   
                    <span className="address">
                      Registered Charity Number:{' '}
                      {charityInfo.RegisteredCharityNumber}
                    </span>
                  </div>
                </div>

                <div
                  className="v-details__info-title"
                  style={{
                    textAlign: 'left',
                    fontSize: '1rem',
                    fontWeight: 600,
                    marginTop: '2rem'
                  }}
                >
                  LOCATION
                </div>
                <div
                  className="org-wrapper"
                  style={{ margin: '0', marginBottom: '1rem' }}
                >
                  <div>
                    <span className="address">
                      {current_volunteering.address}
                    </span>
                  </div>
                </div>
                <div className="v-details__location--map">
                  <GoogleMaps
                    lat={current_volunteering.latitude}
                    lon={current_volunteering.longitude}
                  />
                </div>
                {!is_cco_loggedin && (
                  <div className="v-details__share">
                    <div>
                      <SocialShare
                        ShareUrl={`https://www.helpbritain.org.uk/volunteering/${this.props.match.params.id}`}
                        linkUrl={`https://www.helpbritain.org.uk/volunteering/${this.props.match.params.id}`}
                        quote={
                          `I just found this event for ${current_volunteering.name} on HelpBritain - support them!` +
                          '\n'
                        }
                      />
                    </div>
                    <span>
                      Let people know about this event, help us by spreading
                      word through social media
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="v-details">
              <div className="v-details__hero">
                <img src={current_volunteering.bannerImage} alt="" />
              </div>
              <div className="v-details__info">
                <div className="v-details__title">
                  {current_volunteering.name}
                </div>

                <div className="v-details__description">
                  {current_volunteering.summary}
                </div>
                <div className="v-details__required-wrapper">
                  <div className="v-details__info-title">
                    VOLUNTEERS REQUIRED
                  </div>
                  <div className="v-details__required">
                    <div className="v-details__required-stats">
                      <CircularProgress
                        value={
                          (100 *
                            (current_volunteering.volunteers !== undefined
                              ? current_volunteering.volunteers.length
                              : 0)) /
                          current_volunteering.numberOfPeople
                        }
                        text=""
                        width={'100px'}
                        height={'100px'}
                      />
                      <div className="v-details__required-stats--right">
                        {current_volunteering.numberOfPeople !==
                          current_volunteering?.volunteers?.length && (
                          <div className="v-details__required-stats--title">
                            Still{' '}
                            {current_volunteering.numberOfPeople -
                              (current_volunteering.volunteers !== undefined
                                ? current_volunteering.volunteers.length
                                : 0)}{' '}
                            volunteers required
                          </div>
                        )}
                        <div className="v-details__required-stats--number">
                          <span style={{ fontSize: '1.2rem' }}>
                            Total {current_volunteering.numberOfPeople} needed
                          </span>
                          <span style={{ fontSize: '1.2rem' }}>
                            {current_volunteering.volunteers !== undefined
                              ? current_volunteering.volunteers.length
                              : 0}{' '}
                            Signed up
                          </span>
                        </div>
                      </div>
                    </div>
                    {!is_cco_loggedin &&
                      current_volunteering.numberOfPeople !==
                        current_volunteering?.volunteers?.length.toString() && (
                        <div>
                          <AddMeAsVolunteer
                            id={this.props.match.params.id}
                            Trigger={({ handleClick }: any) => (
                              <Button
                                className="v-details__add-me"
                                onClick={handleClick}
                              >
                                Add me as a volunteer
                              </Button>
                            )}
                            getSingleVolunteering={
                              this.props.getSingleVolunteering
                            }
                            ccoAdminEmail={current_volunteering.cco}
                          />
                        </div>
                      )}
                  </div>
                </div>
                {this.props.email === current_volunteering.cco &&
                  current_volunteering?.volunteers?.length > 0 && (
                    <div>
                      <div className="table-cont">
                        <div className="v-details__info-title">
                          VOLUNTEERS INFO
                        </div>
                        <Table striped>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Message</th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_volunteering.volunteers.map((d: any) => (
                              <tr>
                                <td>{d.name}</td>
                                <td>{d.email}</td>
                                <td>{d.message}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
        {!loading && <Footer />}
      </div>
    );
  }
}

export default VolunteeringDetailsContainer(withRouter(VolunteeringDetails));
