import React, { Component, Fragment } from 'react';
import './ManageOrganisations.scss';
import { Table } from 'react-bootstrap';
import Loader from '../../../components/Loader/Loader';
import OrganisationContainer, {
  AdminOrgProps
} from '../container/OrganisationContainer';
import swal from 'sweetalert';

class Organisations extends Component<AdminOrgProps> {
  state = {
    show_dropdown_index: null,
    openFeaturedConfirmModal: false,
    selectedCampaignName: ''
  };

  componentDidMount() {
    this.props.getAllOrganisations();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  myRef: any = React.createRef();

  setDropDown = (i: any, selectedCampaignName: any) => {
    const { show_dropdown_index } = this.state;
    this.setState({
      show_dropdown_index: show_dropdown_index === i ? null : i,
      selectedCampaignName
    });
  };

  handleClickOutside = (e: any) => {
    if (this.myRef.current !== null && !this.myRef.current.contains(e.target)) {
      this.setDropDown(null, '');
    }
  };

  setStatus = (email: any, campaignName: any, params: any) => {
    this.setState(
      {
        show_dropdown_index: null
      },
      () => {
        this.props.changeCampaignStatus(email, "campaign", campaignName, params);
      }
    );
  };

  opneFeaturedOrgModal = (email: any, d: any) => {
    swal({
      title: `Are you sure?`,
      text: `You want to set ${d.campaignName.toLowerCase()} \n as featured campaign.`,
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Confirm']
    })
      .then((result: any) => {
        if (result !== null) {
          this.props.setFeatured(
            email,
            'campaign',
            d.campaignName,
            d.featured === 'false' ? 'true' : 'false'
          );
        }
      })
      .catch(() => {
        console.log('close');
      });
  };

  render() {
    const { show_dropdown_index, selectedCampaignName } = this.state;
    return (
      <Fragment>
        {(this.props.organisationsInfo === '' || this.props.loading) && (
          <Loader />
        )}
        {this.props.organisationsInfo !== '' && (
          <div className="organisatoins-cont">
            <div className="manage-heading">Manage Wishlists</div>
            <div className="org-cont">
              <div className="table-cont">
                <Table striped>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Registered Address</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.organisationsInfo
                      .filter((ds: any) => ds.campaigns !== undefined)
                      .map((d: any) =>
                        d.campaigns.map((dss: any, i: any) => (
                          <tr key={i}>
                            <td>
                              <div
                                className="icon-cont center-cont"
                                onClick={() =>
                                  this.opneFeaturedOrgModal(d.id, dss)
                                }
                              >
                                {dss.featured === 'false' && (
                                  <i className="fa fa-star-o icon"></i>
                                )}
                                {dss.featured === 'true' && (
                                  <i className="fa fa-star icon-active"></i>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="orga-cont">
                                <a
                                  href={`/${d.charityNameUrl}/campaigns/${dss.campaignNameUrl}`}
                                  className="text-1"
                                >
                                  {dss.campaignName}
                                </a>
                                <div>{d.CharityName}</div>
                              </div>
                            </td>
                            <td>
                              <div className="center-cont">
                                {d.Line1 +
                                  ', ' +
                                  d.Line2 +
                                  ', ' +
                                  ', ' +
                                  d.Line3 +
                                  ', ' +
                                  d.Postcode}
                              </div>
                            </td>
                            <td>
                              <div className="center-cont status-cont">
                                <i
                                  className={
                                    dss.status === 'Active'
                                      ? 'fa fa-circle ap'
                                      : 'fa fa-circle ac'
                                  }
                                ></i>
                                <span>{dss.status}</span>
                              </div>
                            </td>
                            <td>
                              <div
                                className="table-dropdown-cont"
                                ref={this.myRef}
                              >
                                <div
                                  className="table-dropdown"
                                  onClick={() =>
                                    this.setDropDown(i, dss.campaignName)
                                  }
                                >
                                  <i className="fa fa-angle-down" />
                                </div>
                                {show_dropdown_index === i &&
                                  dss.campaignName === selectedCampaignName && (
                                    <DropDown
                                      onClick={(status: any) =>
                                        this.setStatus(
                                          d.id,
                                          dss.campaignName,
                                          status
                                        )
                                      }
                                      myref={this.myRef}
                                      status={dss.status}
                                    />
                                  )}
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const DropDown = ({ onClick, myref, status }: any) => {
  return (
    <div className="o-dropdown" ref={myref}>
      {status === 'Active' ? (
        <div className="o-dropdown__item" onClick={() => onClick('Inctive')}>
          Inactive
        </div>
      ) : (
        <div className="o-dropdown__item" onClick={() => onClick('Active')}>
          Active
        </div>
      )}
    </div>
  );
};

export default OrganisationContainer(Organisations);
