import { connect } from 'react-redux';
import { getAdminHomeCards } from '../../../services/Home/actions';
import { changeCCOStatus } from '../../../services/CCOProfile/actions';
import {
  changeAdminCampaignStatus,
  setFeatures
} from '../../../services/Campaigns/actions';
import { getVolunteeringList } from '../../../services/Volunteering/actions';

interface StateProps {
  organisationsInfo: any;
  loading: boolean;
  volunteering: any[];
}

interface DispatchProps {
  getAllOrganisations: () => void;
  changeCCOStatus: (id: any, params: any) => void;
  changeCampaignStatus: (id: any, type: any , campaignName: any, params: any) => void;
  setFeatured: (email: any, type: any, name: any, params: any) => void;
  getVolunteeringList: () => void;
}

export interface AdminOrgProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    loading: state.cco_profile.change_status_loading || state.volunteering.loading,
    organisationsInfo: state.home.campaign_card_info,
    volunteering: state.volunteering.volunteering_list,
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    getAllOrganisations: () => dispatch(getAdminHomeCards()),
    changeCCOStatus: (id: any, params: any) =>
      dispatch(changeCCOStatus(id, params)),
    changeCampaignStatus: (id: any, type: any, campaignName: any, params: any) =>
      dispatch(changeAdminCampaignStatus(id, type, campaignName, params)),
    setFeatured: (email: any, type: any, name: any, params: any) =>
      dispatch(setFeatures(email, type, name, params)),
    getVolunteeringList: () => dispatch(getVolunteeringList())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
