import * as types from './types';
import axios from 'axios';
import { baseUrl, getHeader } from '../../config/api-config';
import { notify } from '../../components/Notifier/Notifier';
import { getHomeCards } from '../Home/actions';
import { doLogout } from '../../config/constants';

export const getUserProfile = () => (dispatch: any) => {
  let email = localStorage.getItem('email');
  dispatch({ type: types.CCO_PROFILE_REQUESTED });
  getHeader();
  axios
    .get(baseUrl + `/hb/getHbUser?email=${email}`)
    .then((payload) => dispatch({ type: types.CCO_PROFILE_SUCCEEDED, payload }))
    .catch((error: any) => {
      dispatch({ type: types.CCO_PROFILE_FAILED });
      // notify.error('Something went wrong', error.response.data);
      doLogout();
    });
};

export const getProfile = () => (dispatch: any) => {
  let email = localStorage.getItem('email');
  dispatch({ type: types.CCO_PROFILE_REQUESTED });
  getHeader();
  axios
    .get(baseUrl + `/hb/getUserInfo?email=${email}`)
    .then((payload) => dispatch({ type: types.CCO_PROFILE_SUCCEEDED, payload }))
    .catch((error: any) => {
      dispatch({ type: types.CCO_PROFILE_FAILED });
      // notify.error('Something went wrong', error?.response?.data);
      doLogout();
    });
};

export const userLogout = () => (dispatch: any) => {
  dispatch({ type: types.USER_LOGOUT });
  doLogout();
};

export const getOrganisationProfile = () => (dispatch: any) => {
  let email = localStorage.getItem('email');
  dispatch({ type: types.CCO_ORG_PROFILE_REQUESTED });
  axios
    .get(baseUrl + `/admin/getCCOProfile?ccoId=CCO-${email}`)
    .then((payload) =>
      dispatch({ type: types.CCO_ORG_PROFILE_SUCCEEDED, payload })
    )
    .catch((error: any) => {
      dispatch({ type: types.CCO_ORG_PROFILE_FAILED });
    });
};

export const saveOrganizationProfile = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.SAVE_ORG_PROFILE_REQUESTED });
  axios
    .post(baseUrl + '/admin/saveCCOProfile', payload)
    .then((payload) => {
      dispatch({ type: types.SAVE_ORG_PROFILE_SUCCEEDED, payload });
      notify.success('Success', 'Profile details saved successfully!');
    })
    .catch((error: any) => {
      dispatch({ type: types.SAVE_ORG_PROFILE_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const updateOrganisationProfile = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.SAVE_ORG_PROFILE_REQUESTED });
  axios
    .post(baseUrl + '/admin/updateProfile?flag=4', payload)
    .then((payload) => {
      dispatch({ type: types.SAVE_ORG_PROFILE_SUCCEEDED, payload });
      notify.success('Success', 'Profile details updated successfully!');
    })
    .catch((error: any) => {
      dispatch({ type: types.SAVE_ORG_PROFILE_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const getCCOUsers = () => (dispatch: any) => {
  let email = localStorage.getItem('email');
  dispatch({ type: types.INVITE_USER_REQUESTED });
  axios
    .get(baseUrl + `/admin/getCCOUsers?cco=CCO-${email}`)
    .then((payload: any) =>
      dispatch({ type: types.INVITE_USER_SUCCEEDED, payload })
    )
    .catch((error: any) => {
      dispatch({ type: types.INVITE_USER_FAILED });
      notify.error('Something went wrong !', error.response.data.message);
    });
};

export const inviteUser = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.INVITE_USER_REQUESTED });
  axios
    .post(baseUrl + '/admin/inviteUser', payload)
    .then((payload) => {
      dispatch(getCCOUsers());
      notify.success('Success', payload.data.message);
    })
    .catch((error: any) => {
      dispatch({ type: types.INVITE_USER_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const deActivateUser = (body: any) => (dispatch: any) => {
  dispatch({ type: types.INVITE_USER_REQUESTED });
  axios
    .post(baseUrl + `/admin/deactivateUser`, body)
    .then((payload: any) => dispatch(getCCOUsers()))
    .catch((error: any) => {
      dispatch({ type: types.INVITE_USER_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const getAllFilters = () => (dispatch: any, getState: any) => {
  const {
    search: { latitude, longitude, location }
  } = getState();
  dispatch({ type: types.FILTER_REQUESTED });
  axios
    .get(
      baseUrl +
        `/hb/getServices?city=${location}&lat=${latitude}&long=${longitude}`
    )
    .then((payload: any) => {
      dispatch({ type: types.FILTER_SUCCEEDED, payload });
    })
    .catch((error: any) => {
      dispatch({ type: types.FILTER_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const changeCCOStatus = (id: any, params: any) => (dispatch: any) => {
  dispatch({ type: types.CHANGE_STATUS_REQUESTED });
  getHeader();
  axios
    .get(baseUrl + `/hb/approveUser?email=${id}&status=${params}`)
    .then(() => {
      dispatch({ type: types.CHANGE_STATUS_SUCCEEDED });
      dispatch(getHomeCards());
      notify.success('Success', 'Status updated successfully.');
    })
    .catch((error: any) => {
      dispatch({ type: types.CHANGE_STATUS_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const getAllLocations = (lat: any, long: any) => (dispatch: any) => {
  dispatch({ type: types.LOCATIONS_REQUESTED });
  axios
    .get(baseUrl + `/hb/getLocation?lat=${lat}&long=-${long}`)
    .then((payload: any) => {
      dispatch({ type: types.LOCATIONS_SUCCEEDED, payload });
      // dispatch(getHomeCards())
    })
    .catch((error: any) => {
      dispatch({ type: types.LOCATIONS_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};
