import React, { Component } from 'react';
import {
  Switch,
  Route,
  withRouter,
  Redirect,
  RouteComponentProps,
  BrowserRouter as Router
} from 'react-router-dom';
import Dashboard from '../../Dashboard/components/Dashboard';
import ManageOrganisations from './ManageOrganisations';
import ManageCampaigns from './ManageCampaigns';
import Header from '../../../components/Header/Header';
import '../styles/admin.scss';
import ManageVolunteers from './ManageVolunteers';

const NavList = [
  // {
  //   icon: '',
  //   label: 'Dashboard',
  //   to: '/admin/dashboard'
  // },
  {
    icon: '',
    label: 'Manage Organisations',
    to: '/admin/manage_organisations'
  },
  {
    icon: '',
    label: 'Manage Wishlists',
    to: '/admin/manage_campaigns'
  },
  {
    icon: '',
    label: 'Manage Volunteering',
    to: '/admin/manage_volunteering'
  }
];

class AdminView extends Component<RouteComponentProps> {
  componentDidMount() {
    const {
      match: { url }
    } = this.props;
    if (url === '/admin') {
      this.props.history.push(window.location.pathname);
    }
  }

  render() {
    const { pathname } = window.location;
    return (
      <>
        <Header />
        <div className="admin">
          <div className="admin-sidebar">
            {NavList.map((item: any) => (
              <div className="admin-sidebar__item" key={item.to}>
                <a
                  href={item.to}
                  className={
                    pathname === item.to
                      ? 'admin-sidebar__item--active'
                      : 'admin-sidebar__item'
                  }
                >
                  {item.label}
                </a>
              </div>
            ))}
          </div>
          <div className="admin-content">
            <Router>
              <Switch>
                <Route path={`/admin/dashboard`} component={Dashboard} />
                <Route
                  path={`/admin/manage_organisations`}
                  component={ManageOrganisations}
                />
                <Route
                  path={`/admin/manage_campaigns`}
                  component={ManageCampaigns}
                />
                <Route
                  path={`/admin/manage_volunteering`}
                  component={ManageVolunteers}
                />
                <Redirect from={`/admin`} to={`/admin/manage_organisations`} />
              </Switch>
            </Router>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AdminView);
