import * as types from './types';
import axios from 'axios';
import { baseUrl, getHeader } from '../../config/api-config';
import { notify } from '../../components/Notifier/Notifier';
import { getAdminCampaigns, getHomeCards } from '../Home/actions';

export const getCampaign = (campaignName: string) => (dispatch: any) => {
  dispatch({ type: types.CAMPAIGNS_REQUESTED });
  axios
    .get(baseUrl + `/hb/getCampaign?campaignNameUrl=${campaignName}`)
    .then((payload) => dispatch({ type: types.CAMPAIGNS_SUCCEEDED, payload }))
    .catch((error: any) => {
      dispatch({ type: types.CAMPAIGNS_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const getCharityWishlists = (charityName: string) => (dispatch: any) => {
  dispatch({ type: types.CHARITY_REQUESTED });
  axios
    .get(baseUrl + `/hb/getCharityWishlists?charityNameUrl=${charityName}`)
    .then((payload) => dispatch({ type: types.CHARITY_SUCCEEDED, payload }))
    .catch((error: any) => {
      dispatch({ type: types.CHARITY_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const getWishlist = (url: string) => (dispatch: any) => {
  dispatch({ type: types.WISHLIST_REQUESTED });
  axios
    .get(baseUrl + `/hb/wishlist?url=${url}`)
    .then((payload) => dispatch({ type: types.WISHLIST_SUCCEEDED, payload }))
    .catch((error: any) => {
      dispatch({ type: types.WISHLIST_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const publish = (data: any, type: any) => (dispatch: any) => {
  let email = localStorage.getItem('email');
  dispatch({ type: types.PUBLISH_REQUESTED });
  getHeader()
  axios
    .post(baseUrl + `/hb/publishCampaign?type=${type}&email=${email}`, data)
    .then((payload) => dispatch({ type: types.PUBLISH_SUCCEEDED, payload }))
    .catch((error: any) => {
      dispatch({ type: types.PUBLISH_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const changeCampaignStatus = (
  type: any,
  campaignName: any,
  status: any
) => (dispatch: any, getState: any) => {
  // const email = localStorage.getItem('email');
  let {
    cco_profile: {
      cco_profile_info: { email }
    }
  } = getState();
  dispatch({ type: types.CAMPAIGNS_REQUESTED });
  getHeader()
  axios
    .get(
      baseUrl +
        `/hb/changeCampaignStatus?email=${email}&campaign=${campaignName}&status=${status}&type=${type}`
    )
    .then(({ data }: any) => {
      dispatch(getAdminCampaigns());
      notify.success('Success', 'Status updated successfully');
    })
    .catch((error: any) => {
      notify.error('Something went wrong', error.response.data);
    });
};

export const changeAdminCampaignStatus = (
  email: any,
  type: any,
  campaignName: any,
  status: any
) => (dispatch: any) => {
  dispatch({ type: types.CAMPAIGNS_REQUESTED });
  getHeader()
  axios
    .get(
      baseUrl +
        `/hb/changeCampaignStatus?email=${email}&type=${type}&campaign=${campaignName}&status=${status}`
    )
    .then(({ data }: any) => {
      dispatch(getHomeCards());
      notify.success('Success', 'Status updated successfully');
    })
    .catch((error: any) => {
      notify.error('Something went wrong', error.response.data);
    });
};

export const setFeatures = (email: any, type: any, name: any, status: any) => (
  dispatch: any
) => {
  dispatch({ type: types.CAMPAIGNS_REQUESTED });
  getHeader()
  axios
    .get(
      baseUrl +
        `/hb/featured?email=${email}&type=${type}&name=${name}&status=${status}`
    )
    .then(({ data }: any) => {
      dispatch(getHomeCards());
      notify.success('Success', 'Status updated successfully');
    })
    .catch((error: any) => {
      notify.error('Something went wrong', error.response.data);
    });
};
