import React, { Component } from 'react';
import { Chart } from 'react-google-charts';

import './dashboard.scss';
import Axios from 'axios';
import ProfileContainer, {
  CCOProfileProps
} from '../container/ProfileContainer';
import Loader from '../../../components/Loader/Loader';
import { baseUrl } from '../../../config/api-config';

class Dashboard extends Component<CCOProfileProps> {
  state = {
    dashboardDetails: {
      activeCampaigns: 0,
      inactiveCampaigns: 0,
      futureCampaigns: 0,
      totalCampaigns: 0
    } as any,
    loading: false
  };
  componentDidUpdate(prevProps: any) {
    const { profile_info, fetch_profile_succeeded } = this.props;

    if (
      fetch_profile_succeeded &&
      fetch_profile_succeeded !== prevProps.fetch_profile_succeeded
    ) {
      this.setState({
        loading: false
      });
      Axios(`${baseUrl}/dashboard/getDashboardData?cco=${profile_info.email}`)
        .then((res) => {
          this.setState({
            dashboardDetails: res.data.data,
            loading: false
          });
        })
        .catch((err) => {
          this.setState({
            loading: false
          });
        });
    }
  }
  getVolunteeringData = () => {
    const { dashboardDetails } = this.state;
    let data = [];

    data.push(['Volunteers', 'Total needed', 'Signed Up']);

    dashboardDetails?.volunteering?.map((v: any) => {
      data.push([
        v.volunteeringName,
        Number(v.requiredVolunteers),
        Number(v.volunteers)
      ]);
    });

    return data;
  };

  getTableVolunteeringData = () => {
    const { dashboardDetails } = this.state;
    let data = [] as any;

    dashboardDetails?.volunteering?.map((v: any) => {
      data.push([
        v.volunteeringName,
        Number(v.requiredVolunteers),
        Number(v.volunteers)
      ]);
    });

    return data;
  };

  render() {
    const { loading, dashboardDetails } = this.state;
    return (
      <>
        {loading && <Loader />}
        <div className="my-profile-cont dashboard">
          <h4 className="dashboard__heading">My Stats</h4>

          <div className="dashboard-content">
            <div className="sub-headers">Wishlists</div>
            <div className="dashboard-content__stats">
              <StatCard
                name="Active Wishlists"
                count={dashboardDetails?.activeCampaigns}
              />
              <StatCard
                name="Inactive Wishlists"
                count={dashboardDetails?.inactiveCampaigns}
              />

              <StatCard
                name="Planned Wishlists"
                count={dashboardDetails?.futureCampaigns}
              />
              <StatCard
                name="Total Wishlists"
                count={dashboardDetails?.totalCampaigns}
              />
            </div>

            <div className="sub-headers">Donations</div>
            <div className="dashboard-content__stats">
              <StatCard name="Total Amount Received" count={'100'} />
            </div>

            <div className="dashboard-content__chart">
              <h4 className="dashboard-content__chart--heading">
                Volunteering
              </h4>
              <div className="dashboard-content__chart--view">
                <div className="dashboard-content__chart--display">
                  <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="Bar"
                    loader={<div>Loading Chart</div>}
                    data={this.getVolunteeringData()}
                    // options={{
                    //   chartArea: { width: '50%' },
                    //   colors: ['#14e528', '#fc4ae8', '#6583d9'],
                    //   isStacked: true,
                    // }}
                    options={{
                      chartArea: { width: '50%' },
                      isStacked: true,
                      colors: ['#14e528', '#fc4ae8', '#6583d9'],
                      hAxis: {
                        title: 'Volunteers',
                        minValue: 0
                      },
                      vAxis: {
                        title: 'Volunteering'
                      }
                    }}
                    rootProps={{ 'data-testid': '2' }}
                  />
                </div>

                <div className="dashboard-content__chart--display">
                  <Chart
                    width={'30rem'}
                    height={'100%'}
                    chartType="Table"
                    loader={<div>Loading Chart</div>}
                    data={[
                      [
                        { type: 'string', label: 'Name' },
                        { type: 'number', label: 'Total Needed' },
                        { type: 'number', label: 'Signed Up' }
                      ],
                      ...this.getTableVolunteeringData()
                    ]}
                    options={{
                      showRowNumber: true
                    }}
                    rootProps={{ 'data-testid': '1' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProfileContainer(Dashboard);

const StatCard = ({ name, count }: any) => {
  return (
    <div className="stat-card">
      <div className="stat-card__heading">{name}</div>
      <div className="stat-card__count">{count}</div>
    </div>
  );
};
