import React, { Component } from 'react';
import ProfileContainer, {
  CCOProfileProps
} from '../container/ProfileContainer';
import { FormControl, Button } from 'react-bootstrap';
import './OrganisationProfile.scss';
import Loader from '../../../components/Loader/Loader';
import GoogleMaps from '../../../components/GoogleMap/GoogleMap';
import 'react-image-crop/lib/ReactCrop.scss';
import ReactCrop from 'react-image-crop';
import { ReactComponent as UploadIcon } from '../../../images/Dashboard/upload-light.svg';
import swal from 'sweetalert';

import { ReactComponent as CLoseIcon } from '../../../images/close.svg';

class OrganizationProfile extends Component<CCOProfileProps> {
  upload: any;
  uploadLogo: any;
  imageRef: any;
  logoImageRef: any;

  state = {
    bannerImage: '',
    oneLiner: '',
    description: '',
    bannerCrop: {
      x: 0,
      y: 0,
      width: 800,
      height: 300,
      aspect: 5 / 2
    },
    openCropModal: false,
    bannerCroppedImageUrl: '',

    logo: '',
    openLogoCropModal: false,
    logoCrop: {
      x: 0,
      y: 0,
      width: 120,
      height: 120,
      aspect: 2 / 2
    },
    logoCroppedImageUrl: '',
    id: '',
    isEdit: true
  };

  componentDidMount() {
    if (this.props.profile_info === '') {
      this.setState({
        isEdit: true
      });
    }
    if (this.props.profile_info !== '') {
      this.setState({
        bannerImage:
          this.props.profile_info.charity.banner !== undefined
            ? this.props.profile_info.charity.banner
            : '',
        logo:
          this.props.profile_info.charity.logo !== undefined
            ? this.props.profile_info.charity.logo
            : '',
        oneLiner:
          this.props.profile_info.charity.oneLiner !== undefined
            ? this.props.profile_info.charity.oneLiner
            : '',
        description:
          this.props.profile_info.charity.description !== undefined
            ? this.props.profile_info.charity.description
            : '',
        id: this.props.profile_info.charity.id
      });
    }
  }

  setInitialState = () => {
    if (this.props.profile_info !== '') {
      this.setState({
        bannerImage:
          this.props.profile_info.charity.banner !== undefined
            ? this.props.profile_info.charity.banner
            : '',
        logo:
          this.props.profile_info.charity.logo !== undefined
            ? this.props.profile_info.charity.logo
            : '',
        oneLiner:
          this.props.profile_info.charity.oneLiner !== undefined
            ? this.props.profile_info.charity.oneLiner
            : '',
        description:
          this.props.profile_info.charity.description !== undefined
            ? this.props.profile_info.charity.description
            : '',
        id: this.props.profile_info.charity.id
      });
    }
  };

  handleInputChange = (ev: any) => {
    this.setState({
      [ev.target.name]: ev.target.value
    });
  };

  onInputClick = (event: any) => {
    event.target.value = '';
  };

  onLogoInputClick = (event: any) => {
    event.target.value = '';
  };

  onSelectFile = (e: any) => {
    let _URL = window.URL || window.webkitURL;
    var self = this;
    if (e.target.files && e.target.files.length > 0) {
      const img: any = new Image();
      var file = e.target.files[0];

      let objectUrl = _URL.createObjectURL(e.target.files[0]);
      img.onload = function () {
        if (this.width >= 1200 && this.height >= 600) {
          const reader = new FileReader();

          reader.addEventListener(
            'load',
            () => {
              self.setState({
                bannerImage: reader.result,
                openCropModal: true
              });
            },
            false
          );
          reader.readAsDataURL(file);
        } else {
          // @ts-ignore
          swal({
            title: `You have uploaded a smaller image`,
            text: 'Please upload an image with more than 1200*600 resolution',
            icon: 'warning',
            dangerMode: true,
            // @ts-ignore
            button: {
              text: 'Okay',
              closeModal: true
            }
          });
        }
        _URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    }
  };

  onSelectLogoFile = (e: any) => {
    let _URL = window.URL || window.webkitURL;
    var self = this;
    if (e.target.files && e.target.files.length > 0) {
      const img: any = new Image();
      var file = e.target.files[0];

      let objectUrl = _URL.createObjectURL(e.target.files[0]);
      img.onload = function () {
        if (this.width >= 120 && this.height >= 120) {
          const reader = new FileReader();

          reader.addEventListener(
            'load',
            () => {
              self.setState({
                logo: reader.result,
                openLogoCropModal: true
              });
            },
            false
          );
          reader.readAsDataURL(file);
        } else {
          // @ts-ignore
          swal({
            title: `You have uploaded a smaller image`,
            text: 'Please upload an image with more than 120*120 resolution',
            icon: 'warning',
            dangerMode: true,
            // @ts-ignore
            button: {
              text: 'Okay',
              closeModal: true
            }
          });
        }
        _URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    }
  };

  handleClose = () => {
    this.setState({
      openCropModal: false
    });
  };

  handleCloseModal = () => {
    this.setState({
      openLogoCropModal: false
    });
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onLogoImageLoaded = (image: any) => {
    this.logoImageRef = image;
  };

  onCropComplete = (bannerCrop: any) => {
    this.makeClientCrop(bannerCrop);
  };

  onLogoCropComplete = (logoCrop: any) => {
    this.makeLogoClientCrop(logoCrop);
  };

  onCropChange = (bannerCrop: any, percentCrop: any) => {
    this.setState({ bannerCrop });
  };

  onLogoCropChange = (logoCrop: any, percentCrop: any) => {
    this.setState({ logoCrop });
  };

  async makeClientCrop(bannerCrop: any) {
    if (this.imageRef && bannerCrop.width && bannerCrop.height) {
      const bannerCroppedImageUrl = this.getCroppedImg(
        this.imageRef,
        bannerCrop,
        'newFile.jpeg'
      );
      this.setState({ bannerCroppedImageUrl });
    }
  }

  async makeLogoClientCrop(logoCrop: any) {
    if (this.logoImageRef && logoCrop.width && logoCrop.height) {
      const logoCroppedImageUrl = this.getCroppedImg(
        this.logoImageRef,
        logoCrop,
        'newFile.jpeg'
      );
      this.setState({ logoCroppedImageUrl });
    }
  }

  getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
  }

  saveBannerCroppedImage = () => {
    this.setState({
      bannerImage: this.state.bannerCroppedImageUrl,
      openCropModal: false
    });
  };

  saveLogoCroppedImage = () => {
    this.setState({
      logo: this.state.logoCroppedImageUrl,
      openLogoCropModal: false
    });
  };

  saveOrgProfile = () => {
    const { bannerImage, logo, oneLiner, description } = this.state;
    let payload = {
      banner: bannerImage,
      logo,
      oneLiner,
      description,
      id: localStorage.getItem('email')
    };
    this.props.updateOrgProfile(payload);
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.props.profile_info !== '' &&
      this.props.profile_info !== prevProps.profile_info
    ) {
      this.setState({
        bannerImage:
          this.props.profile_info.charity.banner !== undefined
            ? this.props.profile_info.charity.banner
            : '',
        logo:
          this.props.profile_info.charity.logo !== undefined
            ? this.props.profile_info.charity.logo
            : '',
        oneLiner:
          this.props.profile_info.charity.oneLiner !== undefined
            ? this.props.profile_info.charity.oneLiner
            : '',
        description:
          this.props.profile_info.charity.description !== undefined
            ? this.props.profile_info.charity.description
            : '',
        id: this.props.profile_info.charity.id
      });
    }

    const { openCropModal, openLogoCropModal } = this.state;
    if (
      this.props.save_org_profile_succeeded &&
      this.props.save_org_profile_succeeded !==
        prevProps.save_org_profile_succeeded
    ) {
      if (this.props.setStep !== undefined) {
        this.props.setStep();
      }

      if (this.props.handleEdit !== undefined) {
        this.handleEdit();
      }
    }

    if (
      openLogoCropModal !== prevState.openLogoCropModal ||
      openCropModal !== prevState.openCropModal
    ) {
      document.getElementsByTagName('body')[0].style.overflowY =
        openLogoCropModal || openCropModal ? 'hidden' : 'auto';
    }
  }

  componentWillUnmount(): void {
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
  }

  handleEdit = () => {
    this.setState({
      isEdit: true
    });
  };

  render() {
    const { bannerImage, description, oneLiner, logo } = this.state;
    const { profile_info, loading } = this.props;
    let disable =
      bannerImage.length === 0 ||
      description.length === 0 ||
      oneLiner.length === 0 ||
      logo.length === 0;
    return (
      <div className="main-org-cont">
        {loading && <Loader />}

        <>
          <div className="organization-container">
            <div className="banner-img">
              <input
                id="myInput"
                type="file"
                ref={(ref) => (this.upload = ref)}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={this.onSelectFile}
                onClick={this.onInputClick}
              />
              {bannerImage.length === 0 && (
                <div
                  className="upload-pic"
                  onClick={() => {
                    this.upload.click();
                  }}
                >
                  <UploadIcon className="img" />
                  <span>Upload Banner</span>
                </div>
              )}
              {bannerImage.length > 0 && (
                <div
                  className="img-cont"
                  style={{ backgroundImage: `url(${bannerImage})` }}
                >
                  <div
                    className="middle"
                    onClick={() => {
                      this.upload.click();
                    }}
                    style={!this.state.isEdit ? { display: 'none' } : {}}
                  >
                    <UploadIcon className="img" />
                    <span>Upload new Banner</span>
                  </div>
                </div>
              )}
            </div>
            <div className="about-organization">
              <div className="part-1-org">
                <div className="org-part-1-1">
                  <div>
                    <input
                      id="myInputs"
                      type="file"
                      ref={(ref) => (this.uploadLogo = ref)}
                      style={{ display: 'none' }}
                      accept="image/*"
                      onChange={this.onSelectLogoFile}
                      onClick={this.onLogoInputClick}
                    />
                    <label className="label">Logo</label>
                    {this.state.logo.length === 0 && (
                      <div
                        className="organization-logo"
                        onClick={() => {
                          this.uploadLogo.click();
                        }}
                      >
                        <div className="upload-pic">
                          <UploadIcon className="img" />
                          <span>Upload Logo</span>
                        </div>
                      </div>
                    )}

                    {this.state.logo.length > 0 && (
                      <div className="organization-logo">
                        <div
                          className="img-cont"
                          style={{
                            backgroundImage: `url(${this.state.logo})`
                          }}
                        >
                          <div
                            className="middle"
                            onClick={() => {
                              this.uploadLogo.click();
                            }}
                            style={
                              !this.state.isEdit ? { display: 'none' } : {}
                            }
                          >
                            <UploadIcon className="img" />
                            <span>Upload new logo</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="location-conts">
                    <label htmlFor="">Registered Address</label>
                    <div className="map-container">
                      <GoogleMaps lat={'12'} lon={'13'} />
                    </div>

                    <div className="location">
                      <div>{profile_info?.charity?.Line1}</div>
                      <div>{profile_info?.charity?.Line2}</div>
                      <div>{profile_info?.charity?.Line3}</div>
                      <div>{profile_info?.charity?.Postcode}</div>
                    </div>
                  </div>
                </div>

                <div className="form-container">
                  <label className="label">
                    A one liner statement about your organisation
                  </label>
                  <FormControl
                    type="text"
                    name="oneLiner"
                    onChange={this.handleInputChange}
                    value={oneLiner}
                    className={!this.state.isEdit ? 'disable-edit' : ''}
                    disabled={!this.state.isEdit}
                  />
                  <div style={{ marginTop: '1rem' }}>
                    <label className="label text-label">Description</label>
                    <textarea
                      name="description"
                      value={description}
                      onChange={this.handleInputChange}
                      className={
                        !this.state.isEdit
                          ? 'disable-edit text-box '
                          : 'text-box'
                      }
                      disabled={!this.state.isEdit}
                      minLength={350}
                    />
                  </div>
                </div>
              </div>

              <div className="part-3">
                <label htmlFor="">Services</label>
                <div className="badge-section">
                  {profile_info?.charity?.services.map(
                    (d: any, index: number) => (
                      <div className="service-badge" key={index}>
                        {d}
                      </div>
                    )
                  )}
                </div>
              </div>
              {this.state.isEdit && (
                <div className="btn-container">
                  <Button
                    onClick={this.saveOrgProfile}
                    disabled={disable}
                    className={disable ? 'btn-disable' : 'btn-primary'}
                  >
                    Update
                  </Button>
                  <Button
                    onClick={this.setInitialState}
                    className="edit-btn"
                    style={{ margin: '0 2rem' }}
                  >
                    Discard
                  </Button>
                </div>
              )}
            </div>
          </div>
          {this.state.openCropModal && (
            <div className="cropper-modal">
              <CLoseIcon
                className="cropper-modal-close"
                onClick={this.handleClose}
              />
              {this.state.bannerImage && (
                <div className="cropper-cont">
                  <ReactCrop
                    src={this.state.bannerImage}
                    crop={this.state.bannerCrop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />

                  <Button onClick={this.saveBannerCroppedImage}>Crop</Button>
                </div>
              )}
            </div>
          )}

          {this.state.openLogoCropModal && (
            <div className="cropper-modal">
              <CLoseIcon
                className="cropper-modal-close"
                onClick={this.handleCloseModal}
              />
              {this.state.logo && (
                <div className="cropper-cont">
                  <ReactCrop
                    src={this.state.logo}
                    crop={this.state.logoCrop}
                    onImageLoaded={this.onLogoImageLoaded}
                    onComplete={this.onLogoCropComplete}
                    onChange={this.onLogoCropChange}
                  />

                  <Button onClick={this.saveLogoCroppedImage}>Crop</Button>
                </div>
              )}
            </div>
          )}
        </>
      </div>
    );
  }
}

export default ProfileContainer(OrganizationProfile);
