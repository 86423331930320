import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../styles/addMeAsVolunteer.scss';
import AddMeAsVolunteerContainer, {
  AddMeAsVolunteerProps
} from '../containers/addMeAsVolunteer';
import axios from 'axios';
import { notify } from '../../../components/Notifier/Notifier';
import { baseUrl } from '../../../config/api-config';
import Loader from '../../../components/Loader/Loader';
import { validEmailRegex } from '../../Auth/components/CCO-Registration/CCORegister';
import ReCAPTCHA from 'react-google-recaptcha';

const recaptchaRef: any = React.createRef();

class AddMeAsVolunteer extends Component<AddMeAsVolunteerProps> {
  state = {
    show: false,
    name: '',
    email: this.props.email,
    message: '',
    isLoading: false,
    emailValidationError: null,
    capchaSelected: false
  };

  openModal = () => {
    this.setState({ show: true });
  };

  closeModal = () => {
    this.setState({ show: false });
  };

  handleInput = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleEmailInput = (e: any) => {
    let emailValidation = validEmailRegex.test(e.target.value);
    this.setState({
      emailValidationError: emailValidation,
      [e.target.name]: e.target.value
    });
  };

  handleAdd = () => {
    const { name, email, message } = this.state;
    this.setState({
      isLoading: true
    });
    let payload = {
      name,
      email,
      message,
      id: this.props.id,
      ccoAdminEmail: this.props.ccoAdminEmail
    };
    axios
      .post(baseUrl + '/hbAdmin/addVolunteer', payload)
      .then((data: any) => {
        notify.success('Success', 'Successfuly added volunteer.');
        this.props.getSingleVolunteering(this.props.id);
        this.setState({
          isLoading: false
        });
      })
      .then(() => {
        this.closeModal();
      })
      .catch(() => {
        this.setState({
          isLoading: false
        });
        this.closeModal();
        notify.error('Something went wrong', 'Failed to add volunteers.');
      });
  };

  onChange = () => {
    this.setState({
      capchaSelected: true
    })
  }

  render() {
    const { Trigger, email: profile_email } = this.props;
    const { show, email, message, name , capchaSelected} = this.state;
    let disable =
      (this.state.email !== undefined && this.state.email.length === 0) ||
      message.length === 0 ||
      name.length === 0 || !capchaSelected;
    return (
      <>
        {this.state.isLoading && <Loader />}
        <Trigger handleClick={this.openModal} />
        <Modal
          show={show}
          onHide={this.closeModal}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
          style={{ height: '100%' }}
          className="AV"
        >
          <Modal.Header>
            <Modal.Title>Add Me As Volunteer</Modal.Title>
            <i className="fa fa-close AV-close" onClick={this.closeModal} />
          </Modal.Header>
          <Modal.Body>
            <div className="AV-wrapper">
              <div className="AV-wrapper__input">
                <label className="AV-wrapper__input--label">Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  placeholder="Enter Name"
                  className="AV-wrapper__input--input"
                  onChange={this.handleInput}
                />
              </div>
              <div className="AV-wrapper__input">
                <label className="AV-wrapper__input--label">Email</label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  placeholder="Enter email"
                  className="AV-wrapper__input--input"
                  onChange={this.handleEmailInput}
                  disabled={!!profile_email}
                  autoComplete="off"
                />
                {this.state.emailValidationError !== null &&
                !this.state.emailValidationError ? (
                  <label
                    className="AV-wrapper__input--helper-text"
                    style={{ color: 'var(--light-magenta)' }}
                  >
                    Enter Valid Email.
                  </label>
                ) : (
                  <p className="AV-wrapper__input--helper-text">
                    I understand that by clicking add me, my email address will
                    be shared with the Charity
                  </p>
                )}
              </div>
              <div className="AV-wrapper__input">
                <label className="AV-wrapper__input--label">Message</label>
                <textarea
                  name="message"
                  value={message}
                  placeholder="Enter message"
                  className="AV-wrapper__input--input"
                  onChange={this.handleInput}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                {/* <ReCAPTCHA sitekey="6Lc8Y84ZAAAAAM3LjHHbIExXb3uZiHMgDL0TDgWN" /> */}

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lc8Y84ZAAAAAM3LjHHbIExXb3uZiHMgDL0TDgWN"
                    onChange={this.onChange}
                  />

                <button
                  className="btn-primary-green AV__btn"
                  onClick={() => {
                    this.handleAdd();
                  }}
                  disabled={disable}
                  style={ disable ? { opacity: '0.6', cursor: "not-allowed"} : {}}
                >
                  Add Me
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default AddMeAsVolunteerContainer(AddMeAsVolunteer);
