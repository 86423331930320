import * as types from './types';
import axios from 'axios';
import { baseUrl, getHeader } from '../../config/api-config';
import { notify } from '../../components/Notifier/Notifier';

export const getHomeBanner = () => (dispatch: any) => {
  dispatch({ type: types.HOME_BANNER_REQUESTED });
  axios
    .get(baseUrl + '/hb/banner')
    .then((payload) => dispatch({ type: types.HOME_BANNER_SUCCEEDED, payload }))
    .catch((error: any) => {
      dispatch({ type: types.HOME_BANNER_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const getHomeCards = () => (dispatch: any, getState: any) => {
  const {
    search: { latitude, longitude, location }
  } = getState();
  dispatch({ type: types.CAMPAIGNS_CARDS_REQUESTED });
  axios
    .get(
      baseUrl +
        `/hb/homeCards?city=${location}&lat=${latitude}&long=${longitude}`
    )
    .then((payload: any) => {
      dispatch({ type: types.CAMPAIGNS_CARDS_SUCCEEDED, payload });

      let volunteeringList: any = [];
      payload.data
        .filter((ds: any) => ds?.charityStatus?.toUpperCase() === 'APPROVED')
        .forEach((element: any) => {
          if (element.volunteering?.length) {
            volunteeringList.push(...element.volunteering);
          }
        });
      dispatch({
        type: 'FETCH_VOLUNTEERING_LIST_SUCCEEDED',
        payload: volunteeringList
      });
    })
    .catch((error: any) => {
      dispatch({ type: types.CAMPAIGNS_CARDS_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const getAdminCampaigns = () => (dispatch: any) => {
  let email = localStorage.getItem('email');
  dispatch({ type: types.ADMIN_CAMPAGIN_LIST_REQUESTED });
  getHeader()
  axios
    .get(baseUrl + `/hb/homeCardsCCO?user=${email}`)
    .then((payload) => {
      dispatch({ type: types.ADMIN_CAMPAGIN_LIST_SUCCEEDED, payload });
      if (payload?.data?.Item?.volunteering?.length > 0) {
        dispatch({
          type: 'FETCH_VOLUNTEERING_LIST_SUCCEEDED',
          payload: payload.data.Item?.volunteering
        });
      }
    })
    .catch((error: any) => {
      dispatch({ type: types.ADMIN_CAMPAGIN_LIST_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const getAdminHomeCards = () => (dispatch: any, getState: any) => {
  const {
    search: { latitude, longitude }
  } = getState();
  dispatch({ type: types.CAMPAIGNS_CARDS_REQUESTED });
  axios
    .get(baseUrl + `/hb/homeCards?city=all&lat=${latitude}&long=${longitude}`)
    .then((payload) =>
      dispatch({ type: types.CAMPAIGNS_CARDS_SUCCEEDED, payload })
    )
    .catch((error: any) => {
      dispatch({ type: types.CAMPAIGNS_CARDS_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};

export const getFilteredCards = (filter: any, city: any) => (
  dispatch: any,
  getState: any
) => {
  const {
    search: { latitude, longitude }
  } = getState();
  dispatch({ type: types.CAMPAIGNS_CARDS_REQUESTED });
  axios
    .get(
      baseUrl +
        `/hb/filterServices?filter=${filter}&city=${city}&lat=${latitude}&long=${longitude}`
    )
    .then((payload: any) => {
      dispatch({ type: types.CAMPAIGNS_CARDS_SUCCEEDED, payload });
      let volunteeringList: any = [];
      payload.data
        .filter((ds: any) => ds?.charityStatus?.toUpperCase() === 'APPROVED')
        .forEach((element: any) => {
          if (element.volunteering?.length) {
            volunteeringList.push(...element.volunteering);
          }
        });
      dispatch({
        type: 'FETCH_VOLUNTEERING_LIST_SUCCEEDED',
        payload: volunteeringList
      });
    })
    .catch((error: any) => {
      dispatch({ type: types.CAMPAIGNS_CARDS_FAILED });
      notify.error('Something went wrong', error.response.data);
    });
};
