import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import ProfileContainer, {
  CCOProfileProps
} from '../../views/CCOProfile/container/ProfileContainer';
import Loader from '../Loader/Loader';
import NavBar from './NavBar';
import Logo from '../../images/logo.png';
import cutLogo from '../../images/cutLogo.png';
import { NavLink } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import SideBarContent from './SideBarContent';
import MobileNavBar from './MobileNavBar';
import AdminNavbar from './AdminNavBar';

class Header extends Component<CCOProfileProps> {
  state = {
    sidebarOpen: false,
    longitude: '',
    latitude: ''
  };

  componentDidMount() {
    let my = this;
    navigator.geolocation.getCurrentPosition(function (position) {
      my.props.setLatLong({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      });
    });
  }

  onSetSidebarOpen = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    });
  };

  render() {
    const { activeTab } = this.props;
    const is_admin_loggedin = localStorage.getItem('__is_admin_loggedIn');
    return (
      <div className="header-cont">
        {this.props.loading && <Loader />}
        <Navbar expand="md">
          <div
            className="bar-icon-wrapper show-mobile-only"
            onClick={this.onSetSidebarOpen}
          >
            <i className="fa fa-bars" />
          </div>

          <Navbar.Brand>
            <NavLink to="/">
              <img src={Logo} alt="im here" className="show-desktop-only" />
              <img src={cutLogo} alt="im here" className="show-mobile-only" />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {is_admin_loggedin ? (
            <AdminNavbar />
          ) : (
            <NavBar activeTab={activeTab} />
          )}

          {this.state.sidebarOpen && (
            <Sidebar
              sidebar={
                <SideBarContent
                  open={this.state.sidebarOpen}
                  onSetOpen={this.onSetSidebarOpen}
                />
              }
              open={this.state.sidebarOpen}
              onSetOpen={this.onSetSidebarOpen}
              styles={{ sidebar: { background: 'white' } }}
              sidebarClassName="my-sidenav"
            ></Sidebar>
          )}

          <MobileNavBar
            sidebarOpen={this.state.sidebarOpen}
            onSetOpen={this.onSetSidebarOpen}
          />
        </Navbar>
      </div>
    );
  }
}

export default ProfileContainer(Header);
